import React, { useState, useEffect } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormInput,
  CContainer,
  CRow,
  CCol
} from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';

interface CalendarEvent {
  id: string;
  summary: string;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
  location?: string;
}

const GoogleCalendarComponent = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [newEvent, setNewEvent] = useState({
    summary: '',
    start: '',
    end: '',
    location: ''
  });

  useEffect(() => {
    const loadScripts = async () => {
      const gsiScript = document.createElement('script');
      gsiScript.src = 'https://accounts.google.com/gsi/client';
      gsiScript.async = true;
      document.body.appendChild(gsiScript);

      const apiScript = document.createElement('script');
      apiScript.src = 'https://apis.google.com/js/api.js';
      apiScript.async = true;
      document.body.appendChild(apiScript);

      apiScript.onload = () => {
        window.gapi.load('client', initializeGapiClient);
      };

      gsiScript.onload = () => initializeGoogleSignIn();
    };

    loadScripts();
  }, []);

  const initializeGapiClient = async () => {
    try {
      await window.gapi.client.init({
        apiKey: '805941593803-kefv580rkfrfn2s41ismlapnpjteqp26.apps.googleusercontent.com',
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
      });
    } catch (error) {
      console.error('Error initializing GAPI client:', error);
    }
  };

  const initializeGoogleSignIn = () => {
    window.google.accounts.id.initialize({
      client_id: '805941593803-kefv580rkfrfn2s41ismlapnpjteqp26.apps.googleusercontent.com',
      callback: handleCredentialResponse,
      scope: 'https://www.googleapis.com/auth/calendar.readonly'
    });

    window.google.accounts.id.prompt();
  };

  const handleCredentialResponse = async (response: any) => {
    if (response.credential) {
      setIsSignedIn(true);
      await fetchEvents();
    }
  };

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const response = await window.gapi.client.calendar.events.list({
        'calendarId': 'primary',
        'timeMin': (new Date()).toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 10,
        'orderBy': 'startTime'
      });

      setEvents(response.result.items);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOutClick = () => {
    window.google.accounts.id.disableAutoSelect();
    setIsSignedIn(false);
    setEvents([]);
  };

  const handleCreateEvent = async () => {
    try {
      await window.gapi.client.calendar.events.insert({
        'calendarId': 'primary',
        'resource': {
          'summary': newEvent.summary,
          'start': {
            'dateTime': new Date(newEvent.start).toISOString(),
            'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          'end': {
            'dateTime': new Date(newEvent.end).toISOString(),
            'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          'location': newEvent.location
        }
      });

      setShowEventModal(false);
      setNewEvent({ summary: '', start: '', end: '', location: '' });
      fetchEvents();
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const formatDateTime = (dateTimeStr: string) => {
    const date = new Date(dateTimeStr);
    return date.toLocaleString();
  };

  return (
    <CContainer>
      <CCard className="mt-4">
        <CCardHeader className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Google Calendar</h4>
          {isSignedIn ? (
            <CButton color="danger" onClick={handleSignOutClick}>Sign Out</CButton>
          ) : (
            <CButton color="primary" onClick={() => window.google.accounts.id.prompt()}>Sign In</CButton>
          )}
        </CCardHeader>
        <CCardBody>
          {isSignedIn && (
            <>
              <CButton
                color="primary"
                className="mb-3"
                onClick={() => setShowEventModal(true)}
              >
                Create Event
              </CButton>

              {isLoading ? (
                <div>Loading events...</div>
              ) : (
                <div className="calendar-events">
                  {events.map((event) => (
                    <CCard key={event.id} className="mb-3">
                      <CCardBody>
                        <h5>{event.summary}</h5>
                        <p className="text-muted mb-1">
                          Start: {formatDateTime(event.start.dateTime)}
                        </p>
                        <p className="text-muted mb-1">
                          End: {formatDateTime(event.end.dateTime)}
                        </p>
                        {event.location && (
                          <p className="text-muted mb-0">
                            Location: {event.location}
                          </p>
                        )}
                      </CCardBody>
                    </CCard>
                  ))}
                </div>
              )}
            </>
          )}
        </CCardBody>
      </CCard>

      <CModal
        visible={showEventModal}
        onClose={() => setShowEventModal(false)}
      >
        <CModalHeader>
          <CModalTitle>Create New Event</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow className="mb-3">
            <CCol>
              <CFormInput
                type="text"
                placeholder="Event Title"
                value={newEvent.summary}
                onChange={(e) => setNewEvent({ ...newEvent, summary: e.target.value })}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <CFormInput
                type="datetime-local"
                label="Start Time"
                value={newEvent.start}
                onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
              />
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol>
              <CFormInput
                type="datetime-local"
                label="End Time"
                value={newEvent.end}
                onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CFormInput
                type="text"
                placeholder="Location"
                value={newEvent.location}
                onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
              />
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => setShowEventModal(false)}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={handleCreateEvent}
          >
            Create Event
          </CButton>
        </CModalFooter>
      </CModal>
    </CContainer>
  );
};

// Add necessary type declarations
declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (config: any) => void;
          prompt: () => void;
          disableAutoSelect: () => void;
        };
      };
    };
    gapi: {
      load: (api: string, callback: () => void) => void;
      client: {
        init: (config: any) => Promise<void>;
        calendar: {
          events: {
            list: (params: any) => Promise<any>;
            insert: (params: any) => Promise<any>;
          };
        };
      };
    };
  }
}

export default GoogleCalendarComponent;
