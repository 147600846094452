import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export interface FileImage {
  data: string;      // Image data (base64 string or URL)
  title?: string;    // Optional title for the image
}

interface DownloadImagesButtonProps {
  lprData: FileImage[];
  tagsData: FileImage[];
  damagesData: FileImage[];
  angles360Data: FileImage[];
  spotsData: FileImage[];
  instaPhotosData: FileImage[];
}

const DownloadImagesButton: React.FC<DownloadImagesButtonProps> = ({
  lprData = [],
  tagsData = [],
  damagesData = [],
  angles360Data = [],
  spotsData = [],
  instaPhotosData = [],
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);

  // Helper function to convert base64 or URL to blob
  const getImageBlob = async (imageData: string): Promise<Blob> => {
    // Check if the data is a URL
    if (imageData.startsWith('http') || imageData.startsWith('https')) {
      const response = await fetch(imageData);
      return await response.blob();
    }

    // Handle base64 data
    // Remove data URL prefix if present
    const base64Data = imageData.includes('base64,')
      ? imageData.split('base64,')[1]
      : imageData;

    const byteString = atob(base64Data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: 'image/jpeg' });
  };

  // Function to generate a file name from the image title or index
  const getFileName = (image: FileImage, index: number, prefix: string): string => {
    const fileName = image.title
      ? `${image.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.jpg`
      : `${prefix}_${index + 1}.jpg`;
    return fileName;
  };

  // Function to add images to a specific folder in the ZIP
  const addImagesToZip = async (
    zip: JSZip,
    images: FileImage[],
    folderName: string,
    startProgress: number,
    progressStep: number
  ) => {
    if (images.length === 0) return;

    const folder = zip.folder(folderName);

    for (let i = 0; i < images.length; i++) {
      try {
        const blob = await getImageBlob(images[i].data);
        const fileName = getFileName(images[i], i, folderName);
        folder?.file(fileName, blob);

        // Update progress
        const currentProgress = startProgress + (progressStep * (i + 1) / images.length);
        setProgress(Math.min(95, currentProgress)); // Cap at 95% until final download
      } catch (error) {
        console.error(`Error processing image ${i} in ${folderName}:`, error);
      }
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    setProgress(0);

    try {
      const zip = new JSZip();

      // Calculate total number of images to determine progress steps
      const totalImages =
        lprData.length +
        tagsData.length +
        damagesData.length +
        angles360Data.length +
        spotsData.length +
        instaPhotosData.length;

      if (totalImages === 0) {
        setIsDownloading(false);
        alert('No images to download');
        return;
      }

      // Add a README file to explain the contents
      zip.file('README.txt', `
Vehicle Images Archive
---------------------
This archive contains the following directories:
- lpr: License plate recognition images
- tags: Tag images
- damages: Images showing vehicle damages
- angles360: 360-degree view images
- spots: Spot images
- insta: Instagram photos

Generated on: ${new Date().toLocaleString()}
      `);

      // Define progress segments for each category
      const progressStep = 95 / totalImages;
      let currentProgress = 0;

      // Add each category of images to the ZIP
      await addImagesToZip(zip, lprData, 'lpr', currentProgress, progressStep);
      currentProgress += lprData.length * progressStep;

      await addImagesToZip(zip, tagsData, 'tags', currentProgress, progressStep);
      currentProgress += tagsData.length * progressStep;

      await addImagesToZip(zip, damagesData, 'damages', currentProgress, progressStep);
      currentProgress += damagesData.length * progressStep;

      await addImagesToZip(zip, angles360Data, 'angles360', currentProgress, progressStep);
      currentProgress += angles360Data.length * progressStep;

      await addImagesToZip(zip, spotsData, 'spots', currentProgress, progressStep);
      currentProgress += spotsData.length * progressStep;

      await addImagesToZip(zip, instaPhotosData, 'insta', currentProgress, progressStep);

      // Generate the ZIP file
      setProgress(96);
      const content = await zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 6 }
      }, (metadata) => {
        const generationProgress = 96 + (metadata.percent * 0.04);
        setProgress(Math.min(99, generationProgress));
      });

      setProgress(100);

      // Download the ZIP file
      saveAs(content, `vehicle_images_${new Date().getTime()}.zip`);

      setTimeout(() => {
        setIsDownloading(false);
        setProgress(0);
      }, 1000);
    } catch (error) {
      console.error('Error creating ZIP file:', error);
      setIsDownloading(false);
      setProgress(0);
      alert('An error occurred while downloading images. Please try again.');
    }
  };

  return (
    <div className="download-images-container">
      <button
        onClick={handleDownload}
        disabled={isDownloading}
        className="download-button"
      >
        {isDownloading ? 'Preparing Download...' : 'Download Images'}
      </button>

      {isDownloading && (
        <div className="progress-container">
          <div
            className="progress-bar"
            style={{ width: `${progress}%` }}
          />
          <div className="progress-text">{Math.round(progress)}%</div>
        </div>
      )}

      <style >{`
        .download-images-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 300px;
        }

        .download-button {
          padding: 10px 16px;
          background-color: #4a90e2;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .download-button:hover {
          background-color: #357ac1;
        }

        .download-button:disabled {
          background-color: #a0c1e8;
          cursor: not-allowed;
        }

        .progress-container {
          margin-top: 8px;
          width: 100%;
          height: 20px;
          background-color: #f0f0f0;
          border-radius: 10px;
          overflow: hidden;
          position: relative;
        }

        .progress-bar {
          height: 100%;
          background-color: #4caf50;
          transition: width 0.3s ease;
        }

        .progress-text {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #333;
        }
      `}</style>
    </div>
  );
};

export default DownloadImagesButton;
