import { WS_URL } from '../../configuration/constants';
import { Operation } from '../../types';
import { formatMessage } from './formatMessage';
import { Emitter } from '../emitter/Emitter';

export type MessageBody = {
  [key: string]: string;
};


export interface Message {
  id: string;
  url: string;
  body?: MessageBody;
  operation: Operation;
  token?: string;
}

export type Status = 'ok' | 'redirect' | 'error';

export interface Response {
  id: string;
  status: Status;
  data?: never;
}

class WebSocketSingleton {
  private socket: WebSocket | null = null;
  private messageQueue = <Message[]>([]);
  private reconnect = 3000;
  private promisesMap = {}; // To store the resolve functions of promises
  private id = 0;

  constructor() {
    this.connect();
  }

  private connect() {
    const resendFromQueue = () => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.messageQueue.forEach(msg => this.socket?.send(JSON.stringify(msg)));
        this.messageQueue = [];
      } else {
        console.warn('WebSocket still not opened.');
        setTimeout(() => {
          this.connect();
        }, this.reconnect);
      }
    };

    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.socket = new WebSocket(WS_URL);

      this.socket.onopen = () => {
        console.log('WebSocket connection established');
        resendFromQueue();
      };

      this.socket.onmessage = (event) => {
        if (!event) {
          return;
        }
        //console.log('WebSocket message received:', event.data);
        try {
          const message = JSON.parse(event.data);
          if (message === undefined || message === null) {
              return;
          }
          if (message.status) {
            let promise;
            if (message.id) {
              promise = this.promisesMap[message.id];
            }

            switch (message.status) {
              case 'ok':
                if (Array.isArray(message.body) && message.body.length === 1 && message.body[0].data) {
                  promise?.resolve(message.body[0]);
                } else {
                  promise?.resolve(message.body);
                }
                break;
              case 'error':
                promise?.reject(message.code ?? '');
                break;
              case 'redirect':
                if (message.body?.path && window.location.pathname !== message.body?.path) {
                  window.location.href = message.body.path;
                }
                break;
            }
          }
          if (message.method === 'UPDATE') {
            Emitter.getInstance().emit(`${message.url}.status`, message.method);
          }
          if (message.method === 'GET') {
            Emitter.getInstance().emit(`${message.url}.${message.id}`, message.body);
          }
        } catch (error) {
          console.error('error handling message!', error);
        }
      };

      this.socket.onclose = (event) => {
        console.log('WebSocket disconnected:', event.code, event.reason);
        // Automatically try to reconnect if the disconnection was not intentional
        if (event.code !== 1000) { // 1000 denotes a normal closure
          setTimeout(() => this.connect(), this.reconnect); // retry connection after 5 seconds
        }
      };

      this.socket.onerror = (error) => {
        console.log('WebSocket error:', error);
      };
    } else {
      resendFromQueue();
    }
  }

  public send(url: string, operation: Operation, body?: MessageBody): Promise<Response> {
      if (!url || url === '') {
        console.error('Url is empty!');
        return Promise.reject('Url is empty!');
      }

      let future;
      const promise = new Promise<Response>((resolve, reject) => {
        future = {resolve, reject};
      });
      try {
        const id = this.id++ + '';
        const formattedMessage = formatMessage({ id, url, operation, body });
        if (formattedMessage === undefined) {
          console.error('Requested message to send is corrupted!');
          return Promise.reject();
        }
        this.promisesMap[formattedMessage.id] = future; // Store the resolve function using message ID

        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          const message = JSON.stringify(formattedMessage);
          console.log('Requested message to send is: ' + message);
          this.socket.send(message);
        } else {
          this.messageQueue.push(formattedMessage);
          this.connect();
        }
      } catch (error) {
        console.log('error send message', error);
        return Promise.reject(error);
      }
    return promise;
  }

  public close() {
    if (this.socket) {
      this.socket.close();
    }
  }
}
const webSocketSingleton: WebSocketSingleton = new WebSocketSingleton();
export default webSocketSingleton;
