import { AppState, LoaderType, StatusCode } from '../../services/redux/globalStateManager';
import { isRtlLang } from '../../validators';

export const getUserInitialState = (): AppState => {
  const userObj = JSON.parse(localStorage.getItem('user') ?? '{}');

  return {
    theme: userObj.defaultTheme ?? 'dark-theme',
    pages: null,
    loader: {
      type: false as LoaderType,
      status: 200 as StatusCode,
      description: '',
    },
    asideShow: false,
    sidebarShow: true,
    sidebarUnfoldable: window.innerWidth > 768,
    rtl: isRtlLang(userObj?.language?._id?.split('-')[0] ?? navigator.language),
    MainSubheader: null,
    sidebarHover: false,
    primaryColor: userObj.primaryColor ?? 'blue',
    dropdownButtons: ['profile', 'settings', 'messages', 'Help', 'logout'],
    headerButtons: ['_languages', '_themes'],
    views: {},
    routes: null, // No routes until we get them from the server
  };
};
