import { CNavLink } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RouteName, changeTheme } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import webSocket from '../../services/websocket/webSocket';
import Icon from '../mui-icon/Icon';
import StyleWrapper from '../style-wrapper';
import HeaderButton from './HeaderButton';
import LanguageDropdown from './LanguageDropdown';
import Notifications from './Notifications';

type NavRole = 'header' | 'dropdown';
interface NavButton {
  name: RouteName;
  role: NavRole;
  narrow: boolean;
}
const NavButton = (navProps: NavButton) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessage } = webSocket();

  const handleThemeChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault();

    dispatch(changeTheme('toggle'));
  };

  const showHelp = () => {
    // Create a more attractive help dialog
    const helpDiv = document.createElement('div');
    helpDiv.style.position = 'fixed';
    helpDiv.style.top = '50%';
    helpDiv.style.left = '50%';
    helpDiv.style.transform = 'translate(-50%, -50%)';
    helpDiv.style.backgroundColor = 'white';
    helpDiv.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    helpDiv.style.borderRadius = '8px';
    helpDiv.style.padding = '45px';
    helpDiv.style.zIndex = '1000';
    helpDiv.style.maxWidth = '400px';
    helpDiv.style.textAlign = 'center';

    // Help content
    const title = document.createElement('h2');
    title.textContent = t('Dashboard Version');
    title.style.marginBottom = '15px';
    title.style.color = '#333';

    const helpContent = document.createElement('div');
    helpContent.innerHTML = `
            <div style="font-size: 24px; font-weight: bold; margin: 20px 0; color: #4285f4;">2.2.0</div>
            <p style="margin-bottom: 15px; color: #555; line-height: 1.5;">
            </p>
        `;

    // Close button
    const closeButton = document.createElement('button');
    closeButton.textContent = t('Close');
    closeButton.style.padding = '8px 16px';
    closeButton.style.backgroundColor = '#4285f4';
    closeButton.style.color = 'white';
    closeButton.style.border = 'none';
    closeButton.style.borderRadius = '4px';
    closeButton.style.cursor = 'pointer';
    closeButton.style.marginTop = '15px';
    closeButton.onclick = () => document.body.removeChild(helpDiv);

    // Add elements to the help div
    helpDiv.appendChild(title);
    helpDiv.appendChild(helpContent);
    helpDiv.appendChild(closeButton);

    // Add the help div to the body
    document.body.appendChild(helpDiv);
  };


  const buttons: Record<RouteName, { icon: string; to: string; onClick?: (t: unknown) => void }> = {
    _user_profile: {
      icon: 'Portrait',
      to: '/profile',
    },
    _user_settings: {
      icon: 'Settings',
      to: '/settings',
    },
    logout: {
      icon: 'Logout',
      onClick: () => {
        if (window.confirm(t('Are you sure you want to logout?'))) {
          sendMessage('/logout', 'GET')
            .catch(error => {
              console.error(error);
            });

          localStorage.removeItem('user');
          navigate('/login');
        }
      },
    },
    _themes: {
      onClick: handleThemeChange,
      icon: 'Brightness7',
    },
    _languages: {
      Component: LanguageDropdown,
      icon: 'Public',
    },
    _widgets: {
      Component: Notifications,
      narrow: false,
    },
    _pages: {
      narrow: false,
      icon: 'Email',
    },
    'Help': {
      icon: 'HelpOutline',
      onClick: showHelp,
    },
  };

  const props = buttons[navProps.name] ?? {};

  if (props.narrow && navProps.narrow !== props.narrow) {
    return null;
  }

  return (
    <div className={'nav-item-' + navProps.role}>
      {navProps.role === 'dropdown' && (
        <CNavLink
          component="div"
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            }

            if (props.to) {
              navigate(props.to);
            }
          }}
        >
          <StyleWrapper display="flex" gap="0.25rem">
            <Icon name={buttons[navProps.name]?.icon} />
            <span>{navProps.role === 'dropdown' && t(navProps.name)}</span>
          </StyleWrapper>
        </CNavLink>
      )}
      {navProps.role === 'header' && <HeaderButton icon={buttons[navProps.name]?.icon} name={navProps.name} />}
    </div>
  );
};

export default NavButton;
