import React, { useState, useEffect } from 'react';
import FileRetriever from '../../../utils/getters/getFile';
import useSummaryData from '../../../services/useGalleryData';
import Summary from './Gallery';
/**
 * RawDataItem interface
 */
interface RawDataItem {
  id: string;
  title?: string | number;
  type?: string;
}

/**
 * Props for the SummaryContainer component
 */
interface SummaryContainerProps {
  // Raw data from API or localStorage
  rawData?: RawDataItem[];
  // Custom file retriever (optional)
  fileRetriever?: any;
  // Optional callback when process is finished
  onFinish?: () => void;
  // Optional panoramic image reference
  panoramicImgRef?: string;
}

/**
 * Container component that fetches and processes raw data for the Summary component
 */
const SummaryContainer: React.FC<SummaryContainerProps> = ({
  rawData: initialRawData,
  fileRetriever = FileRetriever,
  onFinish,
  panoramicImgRef
}) => {
  // State for raw data (if we need to fetch it)
  const [rawData, setRawData] = useState<RawDataItem[]>(initialRawData || []);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>('');

  // Use our custom hook to process the raw data
  const {
    lprData,
    tagsData,
    damagesData,
    angles360Data,
    spotsData,
    instaPhotosData,
    isLoading,
    error
  } = useSummaryData({
    rawData,
    fileRetriever
  });

  // If no initial data was provided, fetch it from localStorage
  useEffect(() => {
    if (!initialRawData) {
      try {
        // Example of fetching from localStorage
        const storedData = localStorage.getItem('carInspectionData');
        if (storedData) {
          setRawData(JSON.parse(storedData));
        }
      } catch (error) {
        console.error('Error fetching data from localStorage:', error);
      }
    }
  }, [initialRawData]);

  // Handle video selection
  const handleVideoSelected = (file: File, url: string) => {
    setVideoFile(file);
    setVideoUrl(url);
    console.log('Video selected:', file.name);
  };

  // Custom navigation handler
  const handleNavigateToSection = (sectionId: string, path: string) => {
    console.log(`Navigating to section ${sectionId} with path ${path}`);
    // Your navigation logic here
  };

  // Handle finish action
  const handleFinish = () => {
    if (onFinish) {
      onFinish();
    }
  };

  if (error) {
    return <div className="error-message">Error loading data: {error}</div>;
  }

  return (
    <Summary
      lprData={lprData}
      tagsData={tagsData}
      damagesData={damagesData}
      angles360Data={angles360Data}
      spotsData={spotsData}
      instaPhotosData={instaPhotosData}
      panoramicImgRef={panoramicImgRef}
      onNavigateToSection={handleNavigateToSection}
      onFinish={handleFinish}
      onVideoSelected={handleVideoSelected}
    />
  );
};

export default SummaryContainer;
