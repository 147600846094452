import { Buffer } from 'buffer';
import { Emitter } from '../../services/emitter/Emitter';
import { staticRoutes } from '../../configuration/constants';
import { ExtFile } from '../../types';
import WebSocketSingleton from '../../services/websocket/WebSocketSingleton';

class FileRetriever {
    private static pendingIds = new Map<string, Promise<ExtFile>>();
    private socket = WebSocketSingleton;

    constructor() {

    }

    public get(id: string): Promise<ExtFile> {
        let promise = FileRetriever.pendingIds.get(id);
        if (promise !== undefined) {
            return promise;
        }
        promise = new Promise((resolve, reject) => {
            if (!id) {
                reject(new Error('id is required'));
            }

            // Use a more robust structure to handle chunks
            const chunks: Map<number | string, Buffer> = new Map();
            let totalParts = 0;

            const onMessage = (message: {
                part: string | number;
                data: WithImplicitCoercion<string> | { [Symbol.toPrimitive](hint: 'string'): string; };
                total?: number;
            }) => {
                try {
                    // Store the chunk with its proper index
                    const partIndex = typeof message.part === 'string' ? parseInt(message.part, 10) : message.part;

                    // Validate the data before converting to Buffer
                    if (message.data === undefined || message.data === null) {
                        console.error(`❌ Received invalid data for chunk ${partIndex}`);
                        return;
                    }

                    const buffer = Buffer.from(message.data, 'base64');
                    chunks.set(partIndex, buffer);

                    // Track total parts if provided
                    if (message.total) {
                        totalParts = Math.max(totalParts, message.total);
                    }

                    console.log(`ℹ️ Received chunk ${partIndex}, size: ${buffer.length} bytes`);
                } catch (error) {
                    console.error(`❌ Error processing chunk ${message.part}:`, error);
                }
            };

            Emitter.getInstance().on(`${staticRoutes.files}.${id}`, onMessage);

            this.socket.send(staticRoutes.files, 'GET', { filters: { _id: id } })
            .then(done => {
                try {
                    Emitter.getInstance().removeListener(`${staticRoutes.files}.${id}`, onMessage);

                    // Convert chunks map to ordered array
                    const orderedChunks: Buffer[] = [];

                    // First try to use numeric indices
                    const indices = Array.from(chunks.keys())
                        .map(k => typeof k === 'string' ? parseInt(k, 10) : k as number)
                        .filter(n => !isNaN(n))
                        .sort((a, b) => a - b);

                    // Ensure we have all chunks in correct order
                    for (let i = 0; i < indices.length; i++) {
                        const chunk = chunks.get(indices[i]) || chunks.get(indices[i].toString());
                        if (chunk) {
                            orderedChunks.push(chunk);
                        } else {
                            console.warn(`⚠️ Missing chunk ${i} when assembling file ${id}`);
                        }
                    }

                    console.log(`ℹ️ Assembled ${orderedChunks.length} chunks for file ${id}`);

                    // Create file with proper MIME type
                    const file = new File([new Blob(orderedChunks)], done.name, {
                        type: done.type || this.detectMimeType(orderedChunks[0])
                    });

                    if (done.id) {
                        file['id'] = done.id;
                    }

                    FileRetriever.pendingIds.delete(id);
                    resolve(file);
                } catch (e) {
                    console.error(`❌ Error creating file from chunks:`, e);
                    FileRetriever.pendingIds.delete(id);
                    reject(e);
                }
            })
            .catch(error => {
                console.error(`❌ Error fetching file ${id}:`, error);
                Emitter.getInstance().removeListener(`${staticRoutes.files}.${id}`, onMessage);
                FileRetriever.pendingIds.delete(id);
                reject(error);
            });
        });

        FileRetriever.pendingIds.set(id, promise);
        return promise;
    }

    // Helper to detect MIME type from binary data
    private detectMimeType(buffer: Buffer): string {
        if (!buffer || buffer.length < 4) {
            return 'application/octet-stream';
        }

        // Check for JPEG signature (FF D8 FF)
        if (buffer[0] === 0xFF && buffer[1] === 0xD8 && buffer[2] === 0xFF) {
            return 'image/jpeg';
        }

        // Check for PNG signature (89 50 4E 47)
        if (buffer[0] === 0x89 && buffer[1] === 0x50 && buffer[2] === 0x4E && buffer[3] === 0x47) {
            return 'image/png';
        }

        // Check for GIF signature (47 49 46 38)
        if (buffer[0] === 0x47 && buffer[1] === 0x49 && buffer[2] === 0x46 && buffer[3] === 0x38) {
            return 'image/gif';
        }

        // Default to octet-stream for unknown types
        return 'application/octet-stream';
    }
}
const fileRetriever: FileRetriever = new FileRetriever();
export default fileRetriever;
