const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
import React, { useEffect, useState } from 'react';

const UserLocationStaticMap = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          setLocationError('Unable to retrieve location.');
          console.error(err);
        }
      );
    } else {
      setLocationError('Geolocation is not supported by this browser.');
    }
  }, []);

  return (
    <div>
      {locationError && <p>{locationError}</p>}
      {userLocation ? (
        <iframe
          id='mapbox-mp-frame'
          title="User Location Map"
          width="600"
          height="450"
          style={{ border: 0, width: '100%' }}
          loading="lazy"
          allowFullScreen
          src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${userLocation.lat},${userLocation.lng}`}
        ></iframe>
      ) : (
        <p>Loading location...</p>
      )}
    </div>
  );
};

export default UserLocationStaticMap;
