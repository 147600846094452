import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CIcon from '@coreui/icons-react';
import { cilOptions } from '@coreui/icons';
import { CChart, CChartLine, CChartBar } from '@coreui/react-chartjs';
import { getStyle } from '@coreui/utils';
import {
  CRow,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CWidgetStatsA,
  CCard,
  CCardHeader,
  CCardBody,
  CContainer,
} from '@coreui/react';

const Dashboard = () => {
  const { t } = useTranslation();

  // Sample stats values for the widgets ("cubes") - adjusted for a startup
  const stats = {
    users: 178,
    dailyPhotos: 23,
    monthlyPhotos: 542,
    totalPhotos: 2850,
  };

  // Common dropdown action for all widgets
  const dropdownAction = (
    <CDropdown alignment="end">
      <CDropdownToggle color="transparent" caret={false} className="p-0">
        <CIcon icon={cilOptions} className="text-white" />
      </CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem>{t('Action')}</CDropdownItem>
        <CDropdownItem>{t('Another action')}</CDropdownItem>
        <CDropdownItem>{t('Something else here...')}</CDropdownItem>
        <CDropdownItem disabled>{t('Disabled action')}</CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );

  // Improved Monthly Photo Trends Chart (smooth line)
  const monthlyChartRef = useRef(null);

  useEffect(() => {
    const handleColorSchemeChange = () => {
      const chartInstance = monthlyChartRef.current;
      if (chartInstance) {
        const { options } = chartInstance;
        if (options.plugins?.legend?.labels) {
          options.plugins.legend.labels.color = getStyle('--cui-body-color');
        }
        if (options.scales?.x) {
          if (options.scales.x.grid) {
            options.scales.x.grid.color = getStyle('--cui-border-color-translucent');
          }
          if (options.scales.x.ticks) {
            options.scales.x.ticks.color = getStyle('--cui-body-color');
          }
        }
        if (options.scales?.y) {
          if (options.scales.y.grid) {
            options.scales.y.grid.color = getStyle('--cui-border-color-translucent');
          }
          if (options.scales.y.ticks) {
            options.scales.y.ticks.color = getStyle('--cui-body-color');
          }
        }
        chartInstance.update();
      }
    };

    // Handle window resize to make chart responsive
    const handleResize = () => {
      if (monthlyChartRef.current) {
        monthlyChartRef.current.update();
      }
    };

    window.addEventListener('resize', handleResize);
    document.documentElement.addEventListener('ColorSchemeChange', handleColorSchemeChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.documentElement.removeEventListener('ColorSchemeChange', handleColorSchemeChange);
    };
  }, []);

  const monthlyChartData = {
    labels: [
      t('January'),
      t('February'),
      t('March'),
      t('April'),
      t('May'),
      t('June'),
      t('July'),
      t('August'),
      t('September'),
      t('October'),
      t('November'),
      t('December'),
    ],
    datasets: [
      {
        label: t('Monthly Photos'),
        data: [120, 185, 240, 280, 310, 390, 420, 480, 510, 542, 520, 500],
        tension: 0.4, // for a smooth curve
        backgroundColor: 'rgba(59, 130, 246, 0.2)', // Improved color with opacity
        borderColor: 'rgba(59, 130, 246, 0.8)', // More vibrant blue
        pointBackgroundColor: 'rgba(59, 130, 246, 1)',
        pointBorderColor: '#fff',
        borderWidth: 2, // Slightly thicker line
        fill: true,
      },
    ],
  };

  const monthlyChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          color: getStyle('--cui-body-color'),
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        usePointStyle: true,
        callbacks: {
          label: function (context) {
            return `Photos: ${context.raw.toLocaleString()}`;
          }
        }
      },
    },
    scales: {
      x: {
        grid: {
          color: getStyle('--cui-border-color-translucent'),
          drawBorder: false,
          drawOnChartArea: true,
        },
        ticks: {
          color: getStyle('--cui-body-color'),
          padding: 10,
          font: {
            size: 11,
          },
        },
        type: 'category',
      },
      y: {
        grid: {
          color: getStyle('--cui-border-color-translucent'),
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          color: getStyle('--cui-body-color'),
          padding: 10,
          font: {
            size: 11,
          },
          callback: function (value) {
            return value.toLocaleString();
          }
        },
        beginAtZero: true,
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 3,
        hoverRadius: 5,
      }
    },
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 20,
        bottom: 10
      }
    }
  };

  return (
    <CContainer fluid className="px-4">
      {/* Top row with four stats widgets */}
      <CRow className="mb-4 g-4">
        {/* Cube 1: כמות משתמשים */}
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-0"
            color="primary"
            value={<>{stats.users.toLocaleString()}</>}
            title={t('Users')}
            action={dropdownAction}
            chart={
              <CChartLine
                className="mt-3 mx-3"
                style={{ height: '70px' }}
                data={{
                  labels: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul')],
                  datasets: [
                    {
                      label: t('Users'),
                      backgroundColor: 'transparent',
                      borderColor: 'rgba(255,255,255,.55)',
                      data: [95, 120, 140, 155, 165, 172, stats.users],
                    },
                  ],
                }}
                options={{
                  plugins: { legend: { display: false } },
                  maintainAspectRatio: false,
                  scales: { x: { display: false }, y: { display: false } },
                  elements: { line: { borderWidth: 1, tension: 0.4 }, point: { radius: 4, hitRadius: 10, hoverRadius: 4 } },
                }}
              />
            }
          />
        </CCol>

        {/* Cube 2: כמות צילומים יומית */}
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-0"
            color="info"
            value={<>{stats.dailyPhotos.toLocaleString()}</>}
            title={t('Daily Photos')}
            action={dropdownAction}
            chart={
              <CChartLine
                className="mt-3 mx-3"
                style={{ height: '70px' }}
                data={{
                  labels: [t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat'), t('Sun')],
                  datasets: [
                    {
                      label: t('Daily Photos'),
                      backgroundColor: 'transparent',
                      borderColor: 'rgba(255,255,255,.55)',
                      data: [18, 20, 19, 21, 22, 20, stats.dailyPhotos],
                    },
                  ],
                }}
                options={{
                  plugins: { legend: { display: false } },
                  maintainAspectRatio: false,
                  scales: { x: { display: false }, y: { display: false } },
                  elements: { line: { borderWidth: 1, tension: 0.4 }, point: { radius: 4, hitRadius: 10, hoverRadius: 4 } },
                }}
              />
            }
          />
        </CCol>

        {/* Cube 3: כמות צילומים חודשית */}
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-0"
            color="warning"
            value={<>{stats.monthlyPhotos.toLocaleString()}</>}
            title={t('Monthly Photos')}
            action={dropdownAction}
            chart={
              <CChartLine
                className="mt-3 mx-3"
                style={{ height: '70px' }}
                data={{
                  labels: [t('Week 1'), t('Week 2'), t('Week 3'), t('Week 4')],
                  datasets: [
                    {
                      label: t('Monthly Photos'),
                      backgroundColor: 'transparent',
                      borderColor: 'rgba(255,255,255,.55)',
                      data: [495, 510, 525, stats.monthlyPhotos],
                    },
                  ],
                }}
                options={{
                  plugins: { legend: { display: false } },
                  maintainAspectRatio: false,
                  scales: { x: { display: false }, y: { display: false } },
                  elements: { line: { borderWidth: 1, tension: 0.4 }, point: { radius: 4, hitRadius: 10, hoverRadius: 4 } },
                }}
              />
            }
          />
        </CCol>

        {/* Cube 4: כמות צילומין סהכ */}
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-0"
            color="danger"
            value={<>{stats.totalPhotos.toLocaleString()}</>}
            title={t('Total Photos')}
            action={dropdownAction}
            chart={
              <CChartBar
                className="mt-3 mx-3"
                style={{ height: '70px' }}
                data={{
                  labels: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul')],
                  datasets: [
                    {
                      label: t('Total Photos'),
                      backgroundColor: 'rgba(255,255,255,.2)',
                      borderColor: 'rgba(255,255,255,.55)',
                      data: [2400, 2500, 2650, 2750, stats.totalPhotos, 2900, 3000],
                      barPercentage: 0.6,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: { legend: { display: false } },
                  scales: {
                    x: { grid: { display: false }, ticks: { display: false } },
                    y: { grid: { display: false }, ticks: { display: false } },
                  },
                }}
              />
            }
          />
        </CCol>
      </CRow>

      {/* Card containing the improved, smooth Monthly Photo Trends chart */}
      <CRow>
        <CCol>
          <CCard className="mb-4">
            <CCardHeader className="d-flex justify-content-between align-items-center">
              <div>{t('Monthly Photo Trends')}</div>
              <div className="small text-medium-emphasis">{t('2024')}</div>
            </CCardHeader>
            <CCardBody>
              <div style={{ position: 'relative', height: '400px', width: '100%' }}>
                <CChart
                  type="line"
                  data={monthlyChartData}
                  options={monthlyChartOptions}
                  ref={monthlyChartRef}
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Dashboard;
