import { Document, Font, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { PdfSection } from '../../components/base-table/file-viewer/DownloadPdfBtn';
import { FileImage } from '../../components/base-table/file-viewer/Gallery';


Font.register({
  family: 'Hebrew',
  src: '/Open Sans Hebrew Regular.ttf'
});

const minImageSize = {
  width: 101,
  height: 56.81
}

const multiplyFactor = 1.5;


const styles = StyleSheet.create({
  page: {
    padding: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  section: {
    marginBottom: 20,
    width: '100%', display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
    fontFamily: 'Hebrew',
    letterSpacing: 0,
    alignSelf: 'center',
    direction: 'rtl',
    fontWeight: 900,
  },
  photoGrid: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignSelf: 'center',
    gap: 10,
  },
  photo: {
    marginBottom: 10,
    objectFit: 'cover',
    borderRadius: 6,
    position: 'relative',
    aspectRatio: 16 / 9,
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.15)',
    width: minImageSize.width * multiplyFactor + 'px',
    height: minImageSize.height * multiplyFactor + 'px',
  },
  twoColumnContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '20px auto',
    paddingRight: 100,
    justifyContent: 'space-between',
  },
  column: {
    width: '45%',
    textAlign: 'right',
  },
  formSection: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  formLabel: {
    direction: 'rtl',
    textAlign: 'right',
    fontFamily: 'Hebrew',
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 2,
  },
  formValue: {
    direction: 'rtl',
    textAlign: 'right',
    fontFamily: 'Hebrew',
    fontSize: 12,
    marginBottom: 4,
  },
  textWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0, 0.6)',
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  text: {
    fontFamily: 'Hebrew',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: 10,
  },
});


/**
 * Generates a PDF containing images (from the `sections` array)
 * and a final page with read-only form data pulled from localStorage.
 */
export const generatePDFMarkup = async (sections: PdfSection[]) => {
  // 1) Retrieve your saved form data from localStorage
  const formDataStringified = localStorage.getItem('carFormData');
  const formData = formDataStringified ? JSON.parse(formDataStringified) : {
    selectedColor: "",
    monthOnRoad: "",
    testYear: "",
    testMonth: "",
    noTest: "",
    manufacturer: "",
    model: "",
    hands: "",
    ownership: "",
    kilometrage: "",
    licenseNumber: "",
    notes: ""
  };

  // 2) Define your PDF Document using react-pdf’s primitives
  const MyDocument = () => (
    <Document>
      {/* -- Render your photo pages first -- */}
      {Array.from({ length: Math.ceil(sections.length / 2) }, (_, pageIndex) => {
        const pageStartIndex = pageIndex * 2;
        const pageSections = sections.slice(pageStartIndex, pageStartIndex + 2);

        return (
          <Page key={pageIndex} size="A4" style={styles.page}>
            {pageSections.map((section: any, sectionIndex: number) => (
              <View
                key={sectionIndex}
                style={{
                  ...styles.section,
                  marginTop: sectionIndex === 0 ? '' : '150px',
                }}
              >
                <Text style={styles.header}>{section.title}</Text>
                <View style={{
                  ...styles.photoGrid,
                  alignSelf: 'flex-start'

                }}>
                  {section.photos.map((photo: FileImage) => (

                    <View
                      style={styles.photo}
                    >
                      <Image
                        key={photo?.title + Math.random()}
                        src={photo.data}
                        style={{ borderRadius: 6 }}
                      />
                      {photo.title && <View style={styles.textWrapper}>
                        <Text style={styles.text}>{photo.title}</Text>
                      </View>}
                    </View>
                  ))}
                  {section.photos.length === 0 && <Text style={{
                    fontFamily: 'Hebrew',
                    fontSize: 12,
                    textAlign: 'right',
                    alignSelf: 'flex-end',
                    width: '100%',
                  }}>לא צולמו תמונות</Text>}
                </View>
              </View>
            ))}
          </Page>
        );
      })}

      {/* -- Render the read-only view of your form data on the final page -- */}
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>כרטיס רכב</Text>


        <View style={styles.twoColumnContainer}>
          <View style={styles.column}>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>צבע נבחר:</Text>
              <Text style={styles.formValue}>{formData.selectedColor || 'N/A'}</Text>
            </View>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>חודש עלייה לכביש:</Text>
              <Text style={styles.formValue}>{formData.monthOnRoad || 'N/A'}</Text>
            </View>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>
                {formData.noTest ? 'טסט:' : 'טסט עד (שנה/חודש):'}
              </Text>
              <Text style={styles.formValue}>
                {formData.noTest
                  ? 'ללא טסט'
                  : `${formData.testYear || '–––'} / ${formData.testMonth || '–––'}`
                }
              </Text>
            </View>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>יצרן:</Text>
              <Text style={styles.formValue}>{formData.manufacturer || 'N/A'}</Text>
            </View>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>דגם:</Text>
              <Text style={styles.formValue}>{formData.model || 'N/A'}</Text>
            </View>


          </View>

          <View style={styles.column}>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>יד:</Text>
              <Text style={styles.formValue}>{formData.hands || 'N/A'}</Text>
            </View>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>בעלות נוכחית:</Text>
              <Text style={styles.formValue}>{formData.ownership || 'N/A'}</Text>
            </View>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>קילומטראז׳:</Text>
              <Text style={styles.formValue}>{formData.kilometrage || 'N/A'}</Text>
            </View>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>מספר רישוי:</Text>
              <Text style={styles.formValue}>{formData.licenseNumber || 'N/A'}</Text>
            </View>

            <View style={styles.formSection}>
              <Text style={styles.formLabel}>הערות:</Text>
              <Text style={styles.formValue}>{formData.notes || 'N/A'}</Text>
            </View>
          </View>
        </View>

      </Page>


    </Document>
  );

  // 3) Generate and return a Blob of the PDF so it can be downloaded or viewed
  try {
    return await pdf(<MyDocument />).toBlob();

  } catch (e) {
    console.error('Error generating PDF:', e);
    return new Blob([], { type: 'text/plain' });
  }
};


// Public functions
export const downloadPDF = async (sections: PdfSection[], fileName: string = 'document.pdf') => {
  try {
    const blob = await generatePDFMarkup(sections);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw error;
  }
};

