import { Box, CircularProgress } from '@mui/material';
import React, { Suspense, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import '../src/assets/scss/style.scss';
import AppLoader from './components/app-loader/AppLoader';
import Page from './components/page/Page';
import { getDefaultValues, setAppProperties } from './components/settings-panel/SettingsPanel';
import { staticRoutes } from './configuration/constants';
import { AppRoutes, setAppRoutes } from './services/redux/globalStateManager';
import useAppDispatch from './services/redux/useAppDispatch';
import useSendMessage from './services/websocket/webSocket';
import { PageInterface as PageType, WidgetInterface } from './types';
import DefaultLayout from './views/layout/DefaultLayout';
import UserProfile from './views/pages/profile';
import Public from './views/pages/public/Public';
import SettingsPage from './views/pages/settings';
import SystemInitialization from './views/pages/system-initialization/SystemInitialization';
import Roles from './views/pages/roles/Roles';
import Field from './components/form/Field';
import { t } from 'i18next';
import BaseTable from './components/base-table';
import { SAMPLE_IMAGES } from './constants/images';

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));




// TODO: define these on the server
const getWidgetsForRoute = (name: string) => {

  const tableWidget = {
    label: name,
    _type: 'table',
    _content: [
      {
        '_key': 'systemId',
        'type': 'number',
        'label': t('System Id'),
        'size': 'b1000,9999999999',
        'required': true
      },
      {
        '_key': 'licensePlateVin',
        'type': 'text',
        'label': t('License Plate Vin'),
        'size': 'b6,17',
        'required': true,
      },
      {
        '_key': 'business',
        'type': 'text',
        'label': t('Business'),
        'size': 'b2,50',
        'required': true
      },
      {
        '_key': 'user',
        'type': 'text',
        'label': t('User'),
        'size': 'b3,40',
        'required': true
      },
      {
        '_key': 'creationDate',
        'type': 'datetime',
        'label': t('Creation Date'),
        'required': true,
        'defaultValue': 'current_timestamp'
      },
      {
        '_key': 'updateDate',
        'type': 'datetime',
        'label': t('Update Date'),
        'required': true,
        'defaultValue': 'current_timestamp'
      },
      {
        '_key': 'status',
        'type': 'selection',
        'subtype': 'single',
        'label': t('Status'),
        'source': {
          type: 'literal',
          'value': [
            { 'value': 'open', 'label': t('Status_Open') },
            { 'value': 'inProgress', 'label': t('Status_In_Progress') },
            { 'value': 'closed', 'label': t('Status_Closed') },
            { 'value': 'sold', 'label': t('Sold') },
          ],
          'defaultValue': 'open'
        },
      },
      {
        '_key': 'actions',
        'type': 'actions',
        'label': t('Actions'),
        'actions': [
          {
            'type': 'edit',
            'label': t('Action_Edit'),
            'permission': 'edit'
          },
          {
            'type': 'delete',
            'label': t('Action_Delete'),
            'permission': 'delete'
          }
        ]
      }
    ],
    _id: name.toLowerCase(),
    _path: { _type: 'Exact', _id: '/' + name.toLowerCase() },
    permission: 1111,
  };

  switch (name) {
    case 'general':
      return [tableWidget, {
        label: t('Map'),
        _type: 'userLocationMap',
        _content: [],
      }];

    case 'calendar':
      return [{
        label: t('Calendar'),
        _type: 'meetings-calendar',
        _content: [],
      }];

    default:
      return [tableWidget];
  }
};
const TemporaryBaseTable = (name: string): WidgetInterface => ({
  label: name,
  _type: 'TableWidget',
  _content: [
    {
      '_key': 'systemId',
      'type': 'number',
      'label': t('System Id'),
      'size': 'b1000,9999999999',
      permission: 0o0000
    },
    {
      '_key': 'licensePlateVin',
      'type': 'text',
      'label': t('License Plate Vin'),
      'size': 'b6,17',
      'required': true,
      tabTitle: 'General'
    },
    {
      '_key': 'business',
      'type': 'text',
      'label': t('Business Name'),
      'size': 'b2,50',
      tabTitle: 'General'
    },
    {
      '_key': 'user',
      'type': 'text',
      'label': t('User Name'),
      'size': 'b3,40',
      tabTitle: 'General'
    },
    {
      '_key': 'creationDate',
      'type': 'date',
      'label': t('Creation Date'),
    },
    {
      '_key': 'updateDate',
      'type': 'date',
      'label': t('Update Date'),
    },

    // General tab fields
    { tabTitle: 'General', type: 'text', _key: 'note', label: t('Note') },
    { tabTitle: 'General', type: 'text', _key: 'distributed', label: t('Distributed') },
    { tabTitle: 'General', type: 'text', _key: 'affiliation', label: t('Affiliation') },
    { tabTitle: 'General', type: 'text', _key: 'manufacturer', label: t('Manufacturer') },
    { tabTitle: 'General', type: 'text', _key: 'model', label: t('Model') },
    { tabTitle: 'General', type: 'text', _key: 'finishLevel', label: t('Finish Level') },
    { tabTitle: 'General', type: 'text', _key: 'license', label: t('License') },
    { tabTitle: 'General', type: 'text', _key: 'chassis', label: t('Chassis') },
    { tabTitle: 'General', type: 'text', _key: 'orderNumber', label: t('Order Number') },
    { tabTitle: 'General', type: 'text', _key: 'exteriorColor', label: t('Exterior Color') },
    { tabTitle: 'General', type: 'text', _key: 'interiorColor', label: t('Interior Color') },
    { tabTitle: 'General', type: 'date', _key: 'productionDate', label: t('Production Date') },
    { tabTitle: 'General', type: 'date', _key: 'roadEntryDate', label: t('Road Entry Date') },
    { tabTitle: 'General', type: 'number', _key: 'ownerCount', label: t('Owner Count') },
    { tabTitle: 'General', type: 'text', _key: 'originality', label: t('Originality') },
    { tabTitle: 'General', type: 'number', _key: 'kilometers', label: t('Kilometers') },
    // New fields from image
    { tabTitle: 'General', type: 'text', _key: 'engineType', label: t('Engine Type') },
    { tabTitle: 'General', type: 'date', _key: 'deliveryDate', label: t('Delivery Date') },
    { tabTitle: 'General', type: 'date', _key: 'lastServiceDate', label: t('Last Service Date') },
    { tabTitle: 'General', type: 'date', _key: 'nextServiceDate', label: t('Next Service Date') },
    { tabTitle: 'General', type: 'date', _key: 'testDate', label: t('Test Date') },
    {
      tabTitle: 'General',
      type: 'selection',
      _key: 'status',
      label: t('Status'),
      source: {
        type: 'literal',
        value: [
          { option: 'Inventory', label: 'Inventory', key: 'inventory', value: 'Inventory' },
          { option: 'New', label: 'New', key: 'new', value: 'New' },
          { option: 'Sold', label: 'Sold', key: 'sold', value: 'Sold' },
          { option: 'Saved', label: 'Saved', key: 'saved', value: 'Saved' },
          { option: 'Shipping', label: 'Shipping', key: 'shipping', value: 'Shipping' },
          { option: 'Port', label: 'Port', key: 'port', value: 'Port' },
          { option: 'BackInStock', label: 'Back In Stock', key: 'backInStock', value: 'BackInStock' },
          { option: 'Enhanced', label: 'Enhanced', key: 'enhanced', value: 'Enhanced' },
          { option: 'AvailableForSale', label: 'Available For Sale', key: 'availableForSale', value: 'AvailableForSale' }
        ]
      }
    },

    // Finance Fields
    { tabTitle: 'Finance', type: 'date', _key: 'supplierPaymentDate', label: t('Supplier Payment Date') },
    { tabTitle: 'Finance', type: 'date', _key: 'inventoryEntryDate', label: t('Inventory Entry Date') },
    { tabTitle: 'Finance', type: 'number', _key: 'inventoryDays', label: t('Inventory Days') },
    { tabTitle: 'Finance', type: 'text', _key: 'accessories', label: t('Accessories') },
    { tabTitle: 'Finance', type: 'expandable-items', _key: 'localInstallations', label: t('Local Installations'), subtype: 'multiple' },
    { tabTitle: 'Finance', type: 'text', _key: 'internalNumber', label: t('Internal Number') },
    { tabTitle: 'Finance', type: 'text', _key: 'location', label: t('Location') },
    { tabTitle: 'Finance', type: 'text', _key: 'releasedFromCustoms', label: t('Released From Customs') },
    { tabTitle: 'Finance', type: 'date', _key: 'expectedArrival', label: t('Expected Arrival') },
    { tabTitle: 'Finance', type: 'number', _key: 'listPrice', label: t('List Price') },
    { tabTitle: 'Finance', type: 'number', _key: 'purchasePrice', label: t('Purchase Price') },
    { tabTitle: 'Finance', type: 'number', _key: 'vehicleExpenses', label: t('Vehicle Expenses') },
    { tabTitle: 'Finance', type: 'number', _key: 'requiredPrice', label: t('Required Price') },
    { tabTitle: 'Finance', type: 'number', _key: 'actualSalePrice', label: t('Actual Sale Price') },

    // Customer fields
    {
      tabTitle: 'Client', type: 'selection', _key: 'status', label: t('Status'), source: {
        type: 'literal', value: [
          { option: 'Inventory', label: 'Inventory', key: 'inventory', value: 'Inventory' },
          { option: 'New', label: 'New', key: 'new', value: 'New' },
          { option: 'Sold', label: 'Sold', key: 'sold', value: 'Sold' },
          { option: 'Saved', label: 'Saved', key: 'saved', value: 'Saved' },
          { option: 'Shipping', label: 'Shipping', key: 'shipping', value: 'Shipping' },
          { option: 'Port', label: 'Port', key: 'port', value: 'Port' },
          { option: 'BackInStock', label: 'Back In Stock', key: 'backInStock', value: 'BackInStock' },
          { option: 'Enhanced', label: 'Enhanced', key: 'enhanced', value: 'Enhanced' },
          { option: 'AvailableForSale', label: 'Available For Sale', key: 'availableForSale', value: 'AvailableForSale' }
        ]
      }
    },
    { tabTitle: 'Client', type: 'text', _key: 'phone', label: t('Phone'), },
    { tabTitle: 'Client', type: 'text', _key: 'firstName', label: t('First Name'), },
    { tabTitle: 'Client', type: 'text', _key: 'campaign', label: t('Campaign') },
    { tabTitle: 'Client', type: 'text', _key: 'lastName', label: t('Last Name'), },
    { tabTitle: 'Client', type: 'text', _key: 'email', label: t('Email'), },
    { tabTitle: 'Client', type: 'text', _key: 'address', label: t('Address') },
    { tabTitle: 'Client', type: 'text', _key: 'city', label: t('City') },
    { tabTitle: 'Client', type: 'text', _key: 'postalCode', label: t('Postal Code') },
    { tabTitle: 'Client', type: 'number', _key: 'age', label: t('Age') },
    {
      tabTitle: 'Client', type: 'expandable-items', _key: 'interestedCar', label: t('Interested Car'), subtype: 'multiple', source: {
        type: 'literal', value: [
          'Segment',
          'Manufacturer',
          'SubModel',
          'Year',
          'Chassis',
          'Registration',
          'Hand',
          'Km',
          'Note'

        ]
      }
    },

    // Quote fields
    { tabTitle: 'Quote', type: 'text', _key: 'priceList', label: t('Price List') },
    { tabTitle: 'Quote', type: 'text', _key: 'weightedPriceList', label: t('Weighted Price List') },
    { tabTitle: 'Quote', type: 'number', _key: 'kilometers', label: t('Kilometers') },
    { tabTitle: 'Quote', type: 'number', _key: 'ownerCount', label: t('Owner Count') },
    { tabTitle: 'Quote', type: 'text', _key: 'currentOwnership', label: t('Current Ownership') },
    { tabTitle: 'Quote', type: 'text', _key: 'previousOwnership', label: t('Previous Ownership') },
    { tabTitle: 'Quote', type: 'text', _key: 'manufacturer', label: t('Manufacturer') },
    { tabTitle: 'Quote', type: 'text', _key: 'model', label: t('Model') },
    { tabTitle: 'Quote', type: 'text', _key: 'subModel', label: t('Sub Model') },
    { tabTitle: 'Quote', type: 'date', _key: 'productionYear', label: t('Production Year') },
    { tabTitle: 'Quote', type: 'text', _key: 'test', label: t('Test') },
    { tabTitle: 'Quote', type: 'number', _key: 'engineVolume', label: t('Engine Volume') },
    { tabTitle: 'Quote', type: 'text', _key: 'gear', label: t('Gear') },
    { tabTitle: 'Quote', type: 'text', _key: 'color', label: t('Color') },
    { tabTitle: 'Quote', type: 'text', _key: 'modelCode', label: t('Model Code') },
    { tabTitle: 'Quote', type: 'text', _key: 'addCenter', label: t('Add Center') },
    { tabTitle: 'Quote', type: 'text', _key: 'location1', label: t('Location One') },
    { tabTitle: 'Quote', type: 'text', _key: 'location2', label: t('Location Two') },
    { tabTitle: 'Quote', type: 'text', _key: 'location3', label: t('Location Three') },
    { tabTitle: 'Quote', type: 'text', _key: 'location4', label: t('Location Four') },
    { tabTitle: 'Quote', type: 'number', _key: 'centerCount', label: t('Center Count') },
    { tabTitle: 'Quote', type: 'number', _key: 'pricePerCenter', label: t('Price Per Center') },
    { tabTitle: 'Quote', type: 'number', _key: 'priceAfterInspection', label: t('Price After Inspection') },
    { tabTitle: 'Quote', type: 'number', _key: 'marketReduction', label: t('Market Reduction') },
    { tabTitle: 'Quote', type: 'number', _key: 'manualReduction', label: t('Manual Reduction') },
    { tabTitle: 'Quote', type: 'number', _key: 'finalPrice', label: t('Final Price') },
    { tabTitle: 'Map', type: 'userLocationMap', _key: 'map', label: t('Map') },
    {
      tabTitle: 'Gallery', type: 'gallery', _key: 'gallery', label: t('Gallery'), source: {
        type: 'literal',
        value: [
          { base64: SAMPLE_IMAGES[0], label: t('Front') },
          { base64: SAMPLE_IMAGES[1], label: t('Rear') },
          { base64: SAMPLE_IMAGES[2], label: t('Side') },
          { base64: SAMPLE_IMAGES[3], label: t('Interior') },
          { base64: SAMPLE_IMAGES[4], label: t('Dashboard') },
          { base64: SAMPLE_IMAGES[5], label: t('Engine') },
          { base64: SAMPLE_IMAGES[6], label: t('Wheels') },
          { base64: SAMPLE_IMAGES[7], label: t('Trunk') },
        ]
      }
    },
    { tabTitle: 'Ministry of Transport', type: 'ministryOfTransport', _key: 'ministryOfTransport', label: t('Ministry of Transport') },


  ],
  _id: name.toLowerCase(),
  _path: { _type: 'Exact', _id: '/' + name.toLowerCase() },
  permission: 1111,
});

// Remove when server supports new routes
export const fillMissingRoute = (name: string) => {


  return {
    _id: name.toLowerCase(),
    _url: '/' + name.toLowerCase(),
    _widgets: getWidgetsForRoute(name),
    permission: [],
  };
};


export const RouteLoader = () => {
  const { pathname } = useLocation();
  const [page, setPage] = React.useState<PageType | null>(null);
  const { sendMessage } = useSendMessage();

  useEffect(() => {
    if (pathname) {

      let page;

      // Remove this clause when the server supports the new routes
      sendMessage(pathname + '/widgets', 'GET').then(response => {
        page = {
          _id: 'route-loader-' + pathname + '-',
          _url: pathname,
          _widgets: response ?? [],
          permission: [],
        };

        if (!response) {
          page = fillMissingRoute(pathname.replace('/', ''));
        }


        setPage(page);

      })
        ?.catch(error => {
          console.error(error);
        });



    }
  }, []);

  if (process.env.REACT_APP_ENV === 'test ') {





    return <div style={{ maxWidth: '600px', padding: '4rem' }}>


      <Field
        widgetId='test'
        field={{
          _key: 'test',
          type: 'expandable-items',
          label: 'Expandable Items',

        }}
      />
    </div>;
  }

  if (!page) {
    console.warn('page not found: ', page);
    return <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress title={('Loading') + '...'} />
    </Box>;
  }



  return (
    <div className="app-loader-container">
      <AppLoader />
      <DefaultLayout>
        <Page layout="blankLayout" page={page} />
      </DefaultLayout>
    </div>
  );
};

const App = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { sendMessage } = useSendMessage();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const defaultValues = getDefaultValues(user);
    setAppProperties(defaultValues, dispatch, i18n);
    sendMessage('/metadata', 'GET').then(response => {
      dispatch(setAppRoutes(response as AppRoutes));
    })
      ?.catch(error => {
        console.error(error);
      });
  }, [i18n]);

  useEffect(() => {
    // Update the current Url state whenever the url changes, except login page.
    if (
      !location.pathname.startsWith(staticRoutes.login) &&
      !location.pathname.startsWith(staticRoutes.page404) &&
      !location.pathname.startsWith(staticRoutes.page500) &&
      !location.pathname.startsWith(staticRoutes.systemInitialization)
    ) {
      localStorage.setItem('lastPage', location.pathname);
    }
  }, [location]);

  return (
    <Suspense>
      <AppLoader />
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            backgroundColor: 'var(--bt-bg-2)',
            color: 'var(--bt-primary-test)',
          },
        }}
      />

      <Routes>



        {/* Remove this route when the server is ready to serve /roles */}
        <Route
          path="/public/roles"
          element={
            <DefaultLayout>
              <Roles />
            </DefaultLayout>
          }
        />


        <Route
          path="/inventory"
          element={
            <DefaultLayout>
              <BaseTable widget={TemporaryBaseTable('inventory')} />
            </DefaultLayout>
          }
        />
        <Route
          path="/leads"
          element={
            <DefaultLayout>
              <BaseTable widget={TemporaryBaseTable('leads')} />
            </DefaultLayout>
          }
        />
        <Route
          path="/customers"
          element={
            <DefaultLayout>
              <BaseTable widget={TemporaryBaseTable('customers')} />
            </DefaultLayout>
          }
        />
        <Route
          path="/tags"
          element={
            <DefaultLayout>
              <BaseTable widget={TemporaryBaseTable('tags')} />
            </DefaultLayout>
          }
        />
        <Route
          path="/tags"
          element={
            <DefaultLayout>
              <BaseTable widget={TemporaryBaseTable('tags')} />
            </DefaultLayout>
          }
        />
        <Route
          path="/cars-360"
          element={
            <DefaultLayout>
              <BaseTable widget={TemporaryBaseTable('cars-360')} />
            </DefaultLayout>
          }
        />




        <Route path="/system/initialization" element={<SystemInitialization />} />
        <Route path="/public/:page/:subpage" element={<Public />} />
        <Route path="/public/:page/" element={<Public />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path={staticRoutes.page500} element={<Page500 />} />
        <Route path={staticRoutes.page404} element={<Page404 />} />
        <Route path={staticRoutes.login} element={<Login />} />
        <Route path={staticRoutes.login + '/:company'} element={<Login />} />
        <Route path={staticRoutes.dashboard} element={<RouteLoader />} />
        <Route path="/" element={<Navigate to={staticRoutes.dashboard} />} />
        <Route path="" element={<Navigate to={staticRoutes.dashboard} />} />
        <Route path="*" element={<RouteLoader />} />
      </Routes>
    </Suspense>
  );
};

export default App;
