import React, { PropsWithChildren, useMemo } from "react";
import { FileImage } from "./Gallery";
import { Download } from "lucide-react";
import { Button } from "@mui/material";
import { downloadPDF } from "../../../services/pdf/generatePdfMarkup";

export interface PdfSection {
  title: string;
  photos: FileImage[];
}

interface DownloadPdfButtonProps {
  lprData: FileImage[];
  tagsData: FileImage[];
  damagesData: FileImage[];
  angles360Data: FileImage[];
  spotsData: FileImage[];
  instaPhotosData: FileImage[];
}

/**
 * Transforms various FileImage arrays into a structured array of PdfSection objects
 * @param props - Object containing arrays of FileImage data categorized by type
 * @returns Array of PdfSection objects with title and corresponding photos
 */
const transformToPdfSections = (props: DownloadPdfButtonProps): PdfSection[] => {
  const { lprData, tagsData, damagesData, angles360Data, spotsData, instaPhotosData } = props;

  // Array to store all sections
  const sections: PdfSection[] = [];

  // Helper function to add a section if it has photos
  const addSectionIfNotEmpty = (title: string, photos: FileImage[]): void => {
    if (Array.isArray(photos) && photos.length > 0) {
      sections.push({ title, photos });
    }
  };

  // Add each data type as a section if it contains photos
  addSectionIfNotEmpty("License Plate Recognition", lprData);
  addSectionIfNotEmpty("Tags", tagsData);
  addSectionIfNotEmpty("Damages", damagesData);
  addSectionIfNotEmpty("360° Views", angles360Data);
  addSectionIfNotEmpty("Spot Photos", spotsData);
  addSectionIfNotEmpty("Instagram Photos", instaPhotosData);

  return sections;
};

const DownloadPdfButton = (props: PropsWithChildren<DownloadPdfButtonProps>) => {
  // Use useMemo to avoid unnecessary recalculations on re-renders
  const pdfSections = useMemo(() => transformToPdfSections(props), [
    props.lprData,
    props.tagsData,
    props.damagesData,
    props.angles360Data,
    props.spotsData,
    props.instaPhotosData
  ]);

  // The rest of your component logic can go here
  // You can now use pdfSections for generating the PDF

  return (
    <Button
      onClick={() => {
        downloadPDF(pdfSections);
      }}
      variant="contained"
      color="primary"
      startIcon={<Download size={20} />}
      sx={{
        borderRadius: '8px',
        textTransform: 'none',
        padding: '10px 20px',
        fontWeight: 'bold',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      Download PDF
    </Button>

  );
};

export default DownloadPdfButton;
