import { CImage } from '@coreui/react-pro';
import { Box } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import useAppSelect from '../services/redux/useAppSelect';
import { WidgetInterface, WidgetType } from '../types';
import { getRoute, getSource } from '../utils';
import Blank from '../views/blank/Blank';
import ClientView from '../views/pages/union/ClientViewt';
import MultiPageComponent, { MultiPageContext } from '../views/pages/union/MultiPage';
import BaseTable from './base-table';
import Form from './form';
import SelectableList from './form/field-types/SelectableList';
import Gallery from './gallery';
import MapWithUserLocation from './google-map';
import { WidgetProps } from './page/Page';
import Submit from './submit/Submit';
import Summary from './widgets/Summary';
import Text from './text';
import webSocket from '../services/websocket/webSocket';
import UserLocationStaticMap from './UserLocationMap';
import GoogleCalendar from './google-calendar/GoogleCalendar';

export const BTImage = props => {
    const functions = useContext(MultiPageContext);

    const getOnClickStyles = () => {
        if (props.onClick) {
            const onClick = functions.functions[props.onClick.name];
            if (onClick) {
                return {
                    onClick,
                    style: {
                        cursor: 'pointer',
                        ...props.style,
                    },
                };
            } else {
                return () => props.style;
            }
        } else {
            return () => props.style;
        }
    };

    const src = props?.src;

    return <CImage {...props} src={src} {...getOnClickStyles()} width="100%" alt={props.alt ?? ''} />;
};

const Header = props => {
    const [source, setSource] = useState({});
    React.useEffect(() => {
      getSource(props.source).then(value => setSource(value));
    }, []);
    return <p {...props}>{props.title ?? source}</p>;
};

const Button = props => (
    <button type="button" {...props}>
        {props.label}
    </button>
);

const widgetsMap: Record<WidgetType, React.FC<WidgetProps>> = {
    // Maps a react component to 'type' (string) in the JSON
    table: BaseTable,
    TableWidget: BaseTable,
    gallery: Gallery,
    map: MapWithUserLocation,
    // TODO: Implement blank widgets
    audio: Blank,
    calendar: Blank,
    diagram: Blank,
    video: Blank,
    StatisticsWidget: Summary,
    'multi-page': MultiPageComponent,
    form: Form,
    image: BTImage,
    'selectable-list': SelectableList,
    text: Text,
    clientView: ClientView,
    header: Header,
    button: Button,
    submit: Submit,
  'userLocationMap': UserLocationStaticMap,
  'meetings-calendar': GoogleCalendar
    // As we develop more widgets, add them here.
};

export const Widget = (props: { widget: WidgetInterface }) => {
    const { widget } = props;
    // const { handleSource } = useSource();
    const runtime = useAppSelect('runtime');
    const { _type } = widget;
    const { subtype } = widget;
    const { sendMessage } = webSocket();

    let WidgetComponent = widgetsMap[_type];

    if (!WidgetComponent) {
        console.warn('No component found for type: ', _type, 'Using RouteLoader instead');

        WidgetComponent = Blank;
    }

    const [, setSrc] = useState(null);
    useEffect(() => {
      if (widget) {
          const path = getRoute(widget._path);
          if (!path) {
              return;
          }
        sendMessage(path, 'GET')?.then(response => {
          if (response && response.data) {
            setSrc(response.data);
          }
        })
        ?.catch(error => {
          console.error(error);
        });
      }
    }, [_.get(runtime, widget._path?._id)]);

    return (
        <Box id={`${widget._type}.${widget._id}-container`} sx={{ paddingLeft: '0.75rem', paddingRight: '0.75rem', ...props.widget.style }}>
            <WidgetComponent key={widget._id} widget={widget} {...(subtype && { subtype })} />
        </Box>
    );
};
