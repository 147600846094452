import { useState, useEffect } from 'react';
import { FileImage } from '../components/base-table/file-viewer/Gallery'; // Fix import path
import { ExtFile } from '../types'; // Add proper type import

/**
 * Raw data item interface
 */
interface RawDataItem {
  id: string;
  _id?: string; // Add this property to fix type error
  title?: string | number;
  type?: string;
}

/**
 * Return type for the useSummaryData hook
 */
interface SummaryData {
  lprData: FileImage[];
  tagsData: FileImage[];
  damagesData: FileImage[];
  angles360Data: FileImage[];
  spotsData: FileImage[];
  instaPhotosData: FileImage[];
  isLoading: boolean;
  error: string | null;
}

/**
 * FileRetriever interface
 */
interface FileRetriever {
  get(id: string): Promise<ExtFile>;
}

/**
 * Hook props interface
 */
interface UseSummaryDataProps {
  rawData: RawDataItem[];
  fileRetriever: FileRetriever;
}

/**
 * Custom hook to transform raw data into format needed for Summary component
 * Using FileRetriever with FileReader as shown in the example
 */
const useSummaryData = ({
  rawData,
  fileRetriever
}: UseSummaryDataProps): SummaryData => {
  const [summaryData, setSummaryData] = useState<SummaryData>({
    lprData: [],
    tagsData: [],
    damagesData: [],
    angles360Data: [],
    spotsData: [],
    instaPhotosData: [],
    isLoading: true,
    error: null
  });

  useEffect(() => {
    let isMounted = true;
    // Create an array to track object URLs for cleanup
    const objectUrls: string[] = [];

    const processRawData = async () => {
      if (!rawData || !rawData.length) {
        if (isMounted) {
          setSummaryData(prev => ({ ...prev, isLoading: false }));
        }
        return;
      }

      try {
        // Create categorized arrays
        const lprData: FileImage[] = [];
        const tagsData: FileImage[] = [];
        const damagesData: FileImage[] = [];
        const angles360Data: FileImage[] = [];
        const spotsData: FileImage[] = [];
        const instaPhotosData: FileImage[] = [];

        // Process all items
        for (const _item of rawData) {
          const item = {
            ..._item,
            id: _item._id || _item.id // Use id if available, otherwise use _id
          }
          if (!item.id) continue;

          try {
            // Fetch the file
            const fetchedFile = await fileRetriever.get(item.id);

            console.log("fetchedFile: ", fetchedFile);
            if (!fetchedFile) {
              console.error(`File ${item.id} could not be fetched`);
              continue;
            }

            console.log('fetchedFile: ', fetchedFile);
            // Log more details about the file
            console.log('File type:', fetchedFile.type);
            console.log('File size:', fetchedFile.size, 'bytes');

            // Check if the file has actual content
            if (fetchedFile.size === 0) {
              console.error(`File ${item.id} has zero size`);
              continue; // Skip empty files
            }

            // Ensure the file is a valid Blob/File
            if (!(fetchedFile instanceof Blob)) {
              console.error(`Invalid file object for item ${item.id}`);
              continue;
            }

            // Read the file using FileReader to get a base64 data URL
            const fileImageData = await readFileAsDataURL(fetchedFile);
            if (!fileImageData) {
              console.error(`Failed to read file ${item.id} as data URL`);
              continue;
            }

            console.log("fileImageData: ", fileImageData);

            // Create FileImage object with the data URL
            const fileImage: FileImage = {
              data: fileImageData,
              title: item.title?.toString() || ''
            };

            //  <img src={fileImage.data} alt={fileImage.title} />

            console.log("Iterating item to sort: ", item);

            // Categorize by type
            if (item.type === '360') {
              angles360Data.push(fileImage);
            } else if (item.type === 'spot') {
              spotsData.push(fileImage);
            } else if (item.type === 'insta' || item.type === 'instagram') {
              instaPhotosData.push(fileImage);
            } else if (item.type === 'damage') {
              damagesData.push(fileImage);
            } else if (item.title === 'לוחית רישוי') {
              lprData.push(fileImage);
            } else if (!item.title || item.title === '') {
              spotsData.push(fileImage);
            } else {
              // Default to tags if no specific type
              tagsData.push(fileImage);
            }
          } catch (err) {
            console.error(`Error processing item ${item.id}:`, err);
          }
        }
        console.log('total: ', rawData.length, rawData, { lprData, tagsData, damagesData, angles360Data, spotsData, instaPhotosData });

        if (isMounted) {
          setSummaryData({
            lprData,
            tagsData,
            damagesData,
            angles360Data,
            spotsData,
            instaPhotosData,
            isLoading: false,
            error: null
          });
        }
      } catch (error) {
        console.error('Error processing data:', error);
        if (isMounted) {
          setSummaryData(prev => ({
            ...prev,
            isLoading: false,
            error: error instanceof Error ? error.message : 'Unknown error'
          }));
        }
      }
    };

    processRawData();

    // Cleanup function
    return () => {
      isMounted = false;
      // Revoke all object URLs to prevent memory leaks
      objectUrls.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (e) {
          console.error('Error revoking object URL:', e);
        }
      });
    };
  }, [rawData, fileRetriever]);

  return summaryData;
};


/**
 * Helper function to read a file as a data URL using FileReader
 * with improved corruption detection and repair
 */
export function readFileAsDataURL(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    // First validate the file
    if (!file) {
      reject(new Error('❌ Invalid file object'));
      return;
    }

    if (file.size === 0) {
      reject(new Error('❌ File is empty (zero bytes)'));
      return;
    }

    // For images, we can do additional validation
    if (file.type.startsWith('image/')) {
      console.log(`ℹ️ Processing image of type: ${file.type}, size: ${file.size} bytes`);
    }

    const reader = new FileReader();

    // Set up error handler before starting the operation
    reader.onerror = () => {
      console.error('❌ FileReader error:', reader.error);
      reject(reader.error || new Error('Unknown FileReader error'));
    };

    // Handle abort events
    reader.onabort = () => {
      console.error('❌ FileReader operation aborted');
      reject(new Error('File reading aborted'));
    };

    // Handle successful load
    reader.onload = () => {
      if (!reader.result) {
        reject(new Error('❌ FileReader result is empty'));
        return;
      }

      if (typeof reader.result !== 'string') {
        reject(new Error('❌ FileReader result is not a string'));
        return;
      }

      const result = reader.result;

      // Verify data URL format
      if (!result.startsWith('data:')) {
        console.error('❌ Result is not a valid data URL');
        reject(new Error('Invalid data URL format'));
        return;
      }

      // Check for known corruption patterns
      const knownCorruptions = [
        { pattern: "base64,dW5kZWZpbmVk", replacement: "base64," },  // "undefined" in base64
        { pattern: "base64,bnVsbA==", replacement: "base64," },      // "null" in base64
        { pattern: "base64,W29iamVjdA==", replacement: "base64," },  // "[object" in base64
        { pattern: ",,", replacement: "," }                         // Double commas
      ];

      // Only examine and fix the header section of the data
      const headerEndIndex = result.indexOf('base64,') + 7;
      if (headerEndIndex < 7) {
        // No base64 marker found, something is wrong
        console.error('❌ Data URL doesn\'t contain base64 marker');
        reject(new Error('Invalid data URL format - no base64 marker'));
        return;
      }

      // Extract just the header for examination
      const header = result.substring(0, headerEndIndex + 20); // Add a few extra chars

      let fixedResult = result;
      let wasFixed = false;

      // Check for each known corruption pattern in the header
      for (const { pattern, replacement } of knownCorruptions) {
        if (header.includes(pattern)) {
          console.warn(`⚠️ Found corruption "${pattern}" in data URL header, fixing...`);

          // Only replace in the header portion, not the entire data
          const headerPart = result.substring(0, headerEndIndex + 20);
          const dataPart = result.substring(headerEndIndex + 20);

          const fixedHeader = headerPart.replace(pattern, replacement);
          fixedResult = fixedHeader + dataPart;
          wasFixed = true;
          break; // Only apply one fix to avoid cascading issues
        }
      }

      // Basic validation - check if the payload is long enough
      if (fixedResult.length < headerEndIndex + 100) {
        console.warn('⚠️ Data URL payload is suspiciously short');
      }

      if (wasFixed) {
        console.log('✅ Fixed corrupted data URL');
      }

      resolve(fixedResult);
    };

    // Start reading the file
    try {
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('❌ Error initiating file read:', error);
      reject(error);
    }
  });
}

export default useSummaryData;
