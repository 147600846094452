import { CDropdown, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { CircularProgress } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteName, setLoading } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import useSendMessage from '../../services/websocket/webSocket';
import Icon from '../mui-icon/Icon';
import { setAppProperties } from '../settings-panel/SettingsPanel';
import _ from 'lodash';
import { Response } from '../../services/websocket/WebSocketSingleton';

interface HeaderButtonProps {
  // Props Here
  name: RouteName;
  render?: (value: unknown[]) => React.ReactNode;
  icon?: string;
}

const HeaderButton = (props: PropsWithChildren<HeaderButtonProps>) => {
  const { sendMessage } = useSendMessage();
  const [response, setResponse] = useState({});
  const appRoutes = useAppSelect('routes');
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appRoutes && appRoutes[props.name] && appRoutes[props.name] !== '') {
      sendMessage(appRoutes[props.name] ?? '', 'GET')
        ?.then(response => {
          if (response && response.data) {
            setResponse(response.data);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [Boolean(appRoutes)]);

  if (!appRoutes) {
    return null;
  }

  const handleItemClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    const dataValue = e.currentTarget.dataset.value;
    if (dataValue) {
      const option = _.attempt(JSON.parse, dataValue);

      const data = {
        [props.name]: option._id,
      };
      setAppProperties(data, dispatch, i18n);
    }
  };

  return (
    <CDropdown dir="ltr" component="div" variant="nav-item">
      <CDropdownToggle component="div" caret={false}>
        <Icon name={props.icon ?? 'Public'} />
      </CDropdownToggle>
      <CDropdownMenu
        component="div"
        className="pt-0"
        style={{
          left: '50%',
          right: '50%',
          transform: 'translate(-50%, 0) !important',
        }}
      >
        <CDropdownHeader component="div" className="bg-light fw-semibold py-2">
          {t(props.name)}
        </CDropdownHeader>
        {response && Array.isArray(response) ? (
          response?.map(option => (
            <CDropdownItem onClick={handleItemClick} component="div" href="#" key={option._id} data-value={JSON.stringify(option)} style={{ cursor: 'pointer' }}>
              {option.icon}
              <span className="mx-1"></span>
              {t(option.label)}
            </CDropdownItem>
          ))
        ) : (
          <CircularProgress />
        )}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default HeaderButton;
