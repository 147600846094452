import React, { useState, useRef, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Paper,
  IconButton,
  TextField,
  Box,
  Button,
  Collapse,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { keyframes } from '@mui/system';
import Label from '../Label';
import { FieldInterface } from '../../input/types';

// Option type can either be a string or an object with more details.
type OptionType = string | {
  key: string;
  placeholder?: string;
  type?: string;
};

// Our callback data now maps each field to an array of values.
interface CallbackData {
  total: number;
  items: {
    [key: string]: string[];
  };
}

interface TestComponentProps {
  onChange?: (data: CallbackData) => void;
  field: FieldInterface;
  options?: OptionType[];
}

// Each item will have an id and then a flexible set of fields.
interface Item {
  id: string;
  [key: string]: string;
}

const ExtendableItems: React.FC<TestComponentProps> = ({ onChange, field, options, ...props }) => {
  const { t } = useTranslation();

  // Normalize options into an array of objects with a "key", "placeholder", and "type"
  const normalizedOptions = (options && options.length > 0)
    ? options.map(opt => {
      if (typeof opt === 'string') {
        return { key: opt, placeholder: t(opt), type: 'text' };
      }
      return {
        key: opt.key,
        placeholder: opt.placeholder || t(opt.key),
        type: opt.type || 'text'
      };
    })
    : [
      { key: 'name', placeholder: t('Item Name'), type: 'text' },
      { key: 'count', placeholder: t('Item Count'), type: 'number' },
    ];

  // A ref to generate unique IDs.
  const nextId = useRef(0);
  const generateUniqueId = () => {
    nextId.current += 1;
    return nextId.current.toString();
  };

  // Create a new empty item using the normalized field keys.
  const createEmptyItem = (): Item => {
    const newItem: Item = { id: generateUniqueId() };
    normalizedOptions.forEach(opt => {
      newItem[opt.key] = '';
    });
    return newItem;
  };

  const [items, setItems] = useState<Item[]>([createEmptyItem()]);
  const [isFocused, setIsFocused] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const previousCount = useRef<number>(0);

  // Sum up the "count" field if it exists.
  const getTotalCount = (): number => {
    if (field.subtype === 'multiple') {
      return items.length;
    }

    return items.reduce((sum, item) => {
      const count = item.count ? parseInt(item.count) || 0 : 0;
      return sum + count;
    }, 0);
  };

  // Prepare callback data where each field maps to an array of values.
  const prepareCallbackData = (currentItems: Item[]): CallbackData => {
    const dataItems = normalizedOptions.reduce((acc, opt) => {
      // For each normalized field key, collect the corresponding value from every item.
      acc[opt.key] = currentItems.map(item => item[opt.key] || '');
      return acc;
    }, {} as { [key: string]: string[] });

    const data: CallbackData = {
      total: currentItems.reduce((sum, item) => sum + (parseInt(item.count) || 0), 0),
      items: dataItems,
    };

    return data;
  };

  const handleAddItem = () => {
    const newItems = [createEmptyItem(), ...items];
    setItems(newItems);
    onChange && onChange(prepareCallbackData(newItems));
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    onChange && onChange(prepareCallbackData(newItems));
  };

  const handleChangeItem = (index: number, fieldKey: string, value: string) => {
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      [fieldKey]: value,
    };
    setItems(newItems);
    onChange && onChange(prepareCallbackData(newItems));
  };

  useEffect(() => {
    const currentCount = getTotalCount();
    if (currentCount !== previousCount.current) {
      setShouldAnimate(true);
      const timer = setTimeout(() => setShouldAnimate(false), 500);
      previousCount.current = currentCount;
      return () => clearTimeout(timer);
    }
  }, [items]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Trigger callback initially.
  useEffect(() => {
    onChange && onChange(prepareCallbackData(items));
  }, []);

  return (
    <Box
      ref={componentRef}
      sx={{
        p: 1,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Label required={field.required}>
          {field.label}
        </Label>
        {isFocused && (
          <Button
            onClick={() => setIsFocused(false)}
            variant="outlined"
            startIcon={
              <CloseIcon sx={{ '& *': { margin: 0 } }} style={{ margin: '0 !important' }} />
            }
            sx={{
              width: 28,
              height: 28,
              minWidth: 'auto',
              border: 'none',
              aspectRatio: 1,
              borderRadius: '100%',
              backgroundColor: 'rgba(0,0,0,0.1)',
              '& *': { margin: 0 },
            }}
          />
        )}
      </Box>

      <TextField
        value={t('Total') + ': ' + getTotalCount()}
        onClick={() => setIsFocused(true)}
        fullWidth
        variant="outlined"
        size="small"
        InputProps={{
          readOnly: true,
        }}
        sx={{
          mt: 1,
          '& .MuiInputBase-input': {
            color: 'var(--bt-primary-test)',
            cursor: 'pointer',
            backgroundColor: 'var(--cui-input-bg)',
          },
          '& .MuiInputBase-root': {
            animation: shouldAnimate ? `${flashAnimation} 750ms ease-out` : 'none',
            backgroundColor: 'var(--cui-input-bg)',
            borderColor: 'var(--bt-bg-2)',
            '&:hover': {
              borderColor: 'var(--bt-primary-test-10)',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        }}
      />

      <Collapse in={isFocused}>
        <Box>
          <Box sx={{ display: 'flex', gap: 2, mt: 2, mb: 2, justifyContent: 'space-between' }}>
            <Button
              onClick={handleAddItem}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{ borderStyle: 'dashed' }}
            >
              {t('Add Item')}
            </Button>
          </Box>

          <Box
            sx={{
              maxHeight: 500,
              overflowY: 'auto',
              transition: 'max-height 200ms ease-in-out',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': { width: '6px' },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.2)',
                borderRadius: '3px',
              },
            }}
          >
            <TransitionGroup>
              {items.map((item, index) => (
                <Collapse key={item.id}>
                  <Paper
                    elevation={0}
                    sx={{
                      mb: 2,
                      backgroundColor: 'var(--bt-bg)',
                      borderRadius: 1,
                      '&:hover': {
                        '& .remove-button': { opacity: 1 },
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, overflow: 'hidden', minWidth: 0 }}>
                      {normalizedOptions.map(opt => (
                        <TextField
                          key={opt.key}
                          value={item[opt.key] || ''}
                          onChange={(e) => handleChangeItem(index, opt.key, e.target.value)}
                          placeholder={opt.placeholder}
                          variant="outlined"
                          size="small"
                          type={opt.type}
                          sx={{
                            flex: 1,
                            '& .MuiInputBase-input': {
                              color: 'var(--bt-primary-test)',
                              backgroundColor: 'var(--cui-input-bg)',
                            },
                            '& .MuiInputBase-root': {
                              backgroundColor: 'var(--cui-input-bg)',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'var(--bt-bg-2)',
                            },
                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'var(--bt-primary-test-10)',
                            },
                          }}
                        />
                      ))}
                      <IconButton
                        className="remove-button"
                        onClick={() => handleRemoveItem(index)}
                        size="small"
                        sx={{ opacity: 0, transition: 'opacity 200ms ease' }}
                      >
                        <CloseIcon fontSize="small" sx={{ color: 'var(--bt-primary-test)' }} />
                      </IconButton>
                    </Box>
                  </Paper>
                </Collapse>
              ))}
            </TransitionGroup>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ExtendableItems;

const flashAnimation = keyframes`
  0% {
    background-color: transparent;
  }
  25% {
    background-color: var(--bt-bg-10);
  }
`;
