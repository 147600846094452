import { Box, CircularProgress } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { performOCR } from '../../services/ocr/getLicensePlate';
import { updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import {
    createPermissionIndex,
    deletePermissionIndex,
    FieldInterface,
    FieldType,
    readPermissionIndex,
    writePermissionIndex,
} from '../input/types';
import { Mode, SelectionOption } from '../../types';
import { getSource } from '../../utils';
import { getFieldError } from '../../validators/validateField';
import MapWithUserLocation from '../google-map';
import ButtonInput from './field-types/ButtonInput';
import DateRangePicker from './field-types/DateRangePicker';
import File from './field-types/File';
import Map from './field-types/Map';
import Radio from './field-types/Radio';
import SelectableList from './field-types/SelectableList';
import Selection from './field-types/Selection';
import Signature from './field-types/Signature';
import BaseInput from '../input/BaseInput';
import TextArea from './field-types/TextArea';
import RepairCostField from './field-types/RepairCostField';
import Date from './field-types/Date';
import Blank from '../../views/blank/Blank';
import ExpandableItems from './field-types/ExpandableItems';
import Gallery360 from '../Gallery360';
import UserLocationStaticMap from '../UserLocationMap';
import MinistryOfTransport from './field-types/MinistryOfTransport';
import { CFormInput } from '@coreui/react-pro';

export interface FieldComponentProps {
    field: FieldInterface;
    options: SelectionOption[];
    placeholder: string;
    config: FieldInterface;
    onChange: (e: React.ChangeEvent) => void;
    icon?: string;
    onDateChange: (date: Date | null, formatedDate?: string | undefined) => void;
    value: string;
    footer?: boolean;
    closeOnSelect?: boolean;
    confirmButton?: boolean;
    firstDayOfWeek?: number;
    onEndDateChange: (a: unknown, b: unknown) => void;
    onStartDateChange: (a: unknown, b: unknown) => void;
    id: string;
    disabled: boolean;
    shouldFocus: boolean;
    ind: number;
}

const visibleProps = {};
const notVisibleProps = {
    sx: {
        backgroundColor: 'rgba(0,0,0,0.75)',
        opacity: '0.35',
        pointerEvents: 'none' /* Prevents mouse clicks */,
        color: '#a0a0a0' /* Dim text color */,
        borderRadius: '8px',
    },
};

// TODO: These should be moved elsewhere
export const validProps = {
    border: '2px solid green',
    outline: 'none',
    borderRadius: '4px',
};
export const invalidProps = {
    border: '2px solid crimson',
    outline: 'none',
    borderRadius: '4px',
};


// Had a bug where date fields were unnecessarily disabled
// TODO: Figure out how date fields should work without hardcoding the 'disabled' value
const DateFieldWrapper = (props: any) => {
  return <CFormInput {...props} type='date' height={'66px'} disabled={false} />;
};

// Assuming FieldType is an enum or a set of string constants
const fieldTypeToComponent: Record<FieldType, React.FC<FieldComponentProps>> = {
    text: BaseInput,
    textarea: TextArea,
    email: BaseInput,
    password: BaseInput,
    number: BaseInput,
  date: DateFieldWrapper,
    'date-range': DateRangePicker,
    selection: Selection,
    map: Map,
    location: MapWithUserLocation,
    link: BaseInput,
    file: File,
    regex: BaseInput,
    radio: Radio,
    signature: Signature,
    'selectable-list': SelectableList,
    button: ButtonInput,
    repairCostField: RepairCostField,
    autocomplete: Blank,
  'expandable-items': ExpandableItems,
  userLocationMap: UserLocationStaticMap,
  'ministryOfTransport': MinistryOfTransport,
  gallery: Gallery360,
    // ... other field types
};

const Field = ({ field, widgetId }) => {
    const runtime = useAppSelect('runtime');
    const [loading, setLoading] = useState(true);

    const [visible, setVisible] = useState(true);
    const [calculatedValue, setCalculatedValue] = useState('');

    const listeningSource = field?.source?.value;
    const listeningVisible = field._prerequisite?.value;
    const [options, setOptions] = useState([]);
    const dispatch = useAppDispatch();

  const formDefaultValues = runtime[widgetId]?.selectedRow;


    const key = `${widgetId}.${field._key}`;
    let goAhead = true;
    if (field.permission) {
        switch (runtime[widgetId]?.mode) {
            case Mode.VIEW:
                goAhead = String(field.permission)[readPermissionIndex] === '1';
                break;
            case Mode.CREATE:
                goAhead = String(field.permission)[createPermissionIndex] === '1';
                break;
            case Mode.EDIT:
                goAhead = String(field.permission)[writePermissionIndex] === '1';
                break;
            case Mode.DELETE:
                goAhead = String(field.permission)[deletePermissionIndex] === '1';
                break;
        }
    }

    useEffect(() => {
        if (field.source) {
            getSource(field.source).then(value => {
                if (Array.isArray(value) && value.length !== options.length && !value.every((value, index) => value === options[index])) {
                    setOptions(value);
                }
            });
        }
      if (field?.value || (formDefaultValues && field._key in formDefaultValues && formDefaultValues[field._key])) {
        setCalculatedValue(field.value || formDefaultValues[field._key]);
        dispatch(updateRuntimeData(`${key}.value`, field.value || formDefaultValues[field._key], 'merge'));
        dispatch(updateRuntimeData(`${key}.validated`, 'true', 'merge'));
        }
    }, [_.get(runtime, `${key}`)]);

    useEffect(() => {
        setLoading(true);
        const source = _.get(runtime, listeningSource);
        const transform = field.source?.transform;
        if (transform === 'ocr') {
            performOCR(source?.base64).then(res => {
                // setCalculatedValue(res?.digits);
                setLoading(false);
            });
        } else {
            // setCalculatedValue(source);
            setLoading(false);
        }
    }, [_.get(runtime, listeningSource)]);

    useEffect(() => {
        if (!field._prerequisite) {
            setVisible(true);
            return;
        }
        if (_.get(runtime, listeningVisible)) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [_.get(runtime, listeningVisible)]);

    if (!goAhead) {
        if (field.value) {
            dispatch(updateRuntimeData(`${key}.value`, field.value, 'merge'));
            dispatch(updateRuntimeData(`${key}.validated`, 'true', 'merge'));
        }
        return (<></>);
    }

    const Component = fieldTypeToComponent[field.type];

    if (!Component) {
        console.warn('Field of type', field.type, 'is not yet supported.');
        return null;
    }

    const onChange = (e: React.ChangeEvent) => {
        const result = e?.target?.value ?? e?.target?.checked ?? e?.target?.files[0] ?? e;
        setCalculatedValue(result);
        dispatch(updateRuntimeData(`${key}.value`, result, 'merge'));
        const errorMessage = getFieldError(result, field);
        if (errorMessage !== '') {
            dispatch(updateRuntimeData(`${key}.errorMessage`, errorMessage, 'merge'));
            return;
        }
        dispatch(updateRuntimeData(`${key}.validated`, 'true', 'merge'));
        dispatch(updateRuntimeData(`${key}.errorMessage`, '', 'override'));
    };

    const errorMessage = _.get(runtime, `${key}`)?.errorMessage;
    const validated = _.get(runtime, `${key}.validated`) === 'true';
    let dateInput = '';

    if (Component === Date) {
        dateInput = 'px-0';
    }

    return (
      <Box sx={{ minHeight: '66px' }} data-id='field' {...(visible ? visibleProps : notVisibleProps)} padding={0} margin={0}>
            <Box
                className={`form-control ${dateInput}`}
                sx={{ position: 'relative' }}
                {...(validated && errorMessage && invalidProps)}
                {...(validated && errorMessage === '' && validProps)}
            >
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Component
                type={field.type}
                        field={field}
                        options={options ?? []}
                        placeholder={field.placeholder}
                        onChange={onChange}
                        icon={field.icon}
                        onDateChange={onChange}
                        value={calculatedValue}
                        footer
                        closeOnSelect
                        confirmButton
                        firstDayOfWeek={0}
                        onEndDateChange={(_: unknown, b: unknown) => onChange(b)}
                        onStartDateChange={(_: unknown, b: unknown) => onChange(b)}
                        id={key}
                        disabled={visible}
                        shouldFocus={field.ind === 0}
                    />
                )}
            </Box>
            {validated && <span style={{ color: 'red', fontSize: '0.75em' }}>{errorMessage}</span>}
        </Box>
    );
};

export default Field;
