import { cilApplicationsSettings, cilMenu } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CContainer, CHeader, CHeaderBrand, CHeaderNav, CHeaderToggler, CNavItem, CNavLink } from '@coreui/react-pro';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { staticRoutes } from '../configuration/constants';
import { setAsideShow, setSidebarShow } from '../services/redux/globalStateManager';
import useAppDispatch from '../services/redux/useAppDispatch';
import useAppSelect from '../services/redux/useAppSelect';
import UserIcon from './UserIcon';
import NavButton from './header-nav/NavButton';
import { AppBreadcrumb } from './index';
import Logo from './logo';

const AppHeader = (props: { narrow?: boolean }): JSX.Element => {
  const dispatch = useAppDispatch();
  const sidebarShow = useAppSelect('sidebarShow');
  const asideShow = useAppSelect('asideShow');
  const isRtl = useAppSelect('rtl');
  const { t } = useTranslation();
  const MainSubheader = useAppSelect('MainSubheader');
  const headerButtons = useAppSelect('headerButtons');
  const { narrow } = props;

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <CHeader position="sticky" style={{ padding: '0 1rem' }}>
      <div className='app-top-header'>
        <CContainer className='' fluid style={{ display: 'flex' }}>
          {!narrow && isMobile && (
            <CHeaderToggler className="ps-1" onClick={() => dispatch(setSidebarShow(!sidebarShow))}>
              <CIcon icon={cilMenu} size="lg" />
            </CHeaderToggler>
          )}

          {narrow && (
            <CHeaderBrand
              component="div"
              className="mx-auto"
              style={{
                padding: 0,
                flex: 'content',
                alignItems: 'center',
                color: 'rgba(var(--bt-disabled-rgb), 0.75)',
              }}
            >
              <Logo /> {t('betecDashboard')}
            </CHeaderBrand>
          )}

          {!narrow && (
            <CHeaderNav
              component="div"
              className="d-none d-md-flex px-2"
              style={
                isRtl
                  ? {
                    marginLeft: 'auto',
                    marginRight: '4rem',
                    alignSelf: 'center',
                  }
                  : {
                    marginRight: 'auto',
                    alignSelf: 'center',
                  }
              }
            >
              <CNavItem component="div">
                <CNavLink to={staticRoutes.dashboard} component={NavLink}>
                  {t('Dashboard')}
                </CNavLink>
              </CNavItem>
            </CHeaderNav>
          )}

          <CHeaderNav component="div" className="ms-3" style={{ alignItems: 'center' }}>
            {headerButtons.map(button => (
              <NavButton key={button} name={button} role="header" narrow={!narrow} />
            ))}

            {!narrow && (
              <>
                <UserIcon />
                <CHeaderToggler onClick={() => dispatch(setAsideShow(!asideShow))}>
                  <CIcon icon={cilApplicationsSettings} size="lg" style={{ cursor: 'pointer' }} />
                </CHeaderToggler>
              </>
            )}
          </CHeaderNav>
        </CContainer>
      </div>

      {!narrow && (
        <>
          <CContainer
            fluid
            style={{
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            {/* SubHeader area */}
            <AppBreadcrumb />
            {MainSubheader}
          </CContainer>
        </>
      )}
    </CHeader>
  );
};

export default AppHeader;
