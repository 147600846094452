import React, { useState, useEffect } from 'react';
import {
  CForm,
  CFormInput,
  CFormLabel,
  CContainer,
  CRow,
  CCol,
  CSpinner
} from '@coreui/react';
import { useTranslation } from 'react-i18next';

const VehicleForm = () => {
  const [formFields, setFormFields] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await fetch(
          'https://data.gov.il/api/3/action/datastore_search?resource_id=053cea08-09bc-40ec-8f7a-156f0677aff3&limit=1'
        );
        const data = await response.json();

        if (data.success && data.result.records && data.result.records[0]) {
          const firstRecord = data.result.records[0];
          setFormFields(Object.keys(firstRecord));
          setFormData(Object.fromEntries(
            Object.keys(firstRecord).map(key => [key, ''])
          ));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFields();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
  };

  if (loading) {
    return <CSpinner />;
  }

  return (
    <CContainer>
      <CForm style={{ backgroundColor: 'transparent' }} onSubmit={handleSubmit} dir="rtl">
        <CRow>
          {formFields && formFields.map(fieldName => (
            <CCol xs={12} md={6} lg={4} key={fieldName}>
              <div className="mb-3">
                <CFormLabel>{t(fieldName)}</CFormLabel>
                <CFormInput
                  style={{ backgroundColor: 'rgba(255,255,255,0.01)' }}
                  type={fieldName.includes('_dt') ? 'date' : 'text'}
                  id={fieldName}
                  name={fieldName}
                  value={formData[fieldName] || ''}
                  onChange={handleInputChange}
                />
              </div>
            </CCol>
          ))}
        </CRow>
      </CForm>
    </CContainer>
  );
};

export default VehicleForm;
