import {
  CFormLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane
} from '@coreui/react-pro';
import { PropsWithChildren, useState, useMemo, useEffect } from 'react';
import { updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import { Mode, WidgetInterface } from '../../types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { TABVIEW_MIN_FIELDS } from '../../configuration/constants';
import { Widget } from '../WidgetRenderer';
import { FieldInterface } from '../input/types';
import Field from '../form/Field';
import _ from 'lodash';


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SummaryContainer from './file-viewer/GalleryContainer';

const ImagesGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: '1fr',
  '@media (min-width: 600px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (min-width: 960px)': {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
}));

const ImageThumbnail = styled('img')({
  width: '100%',
  aspectRatio: '16/9',
  objectFit: 'cover',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});
interface ExpandedRowModal {
  id: string;
  maxItemsPerTab?: number;
}

const fullWidthFields = ['interestedCar'];

const renderFieldValue = (value: any): React.ReactNode => {
  if (value === null || value === undefined) {
    return '';
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return JSON.stringify(value, null, 2);
  }

  if (typeof value === 'boolean') {
    return value.toString();
  }

  return value;
};

const ExpandedRowModal = (props: PropsWithChildren<ExpandedRowModal>) => {
  const { id, maxItemsPerTab = TABVIEW_MIN_FIELDS } = props;
  const runtime = useAppSelect('runtime');
  const dispatch = useAppDispatch();
  const { mode, fields, modal, selectedRow } = runtime[id] ?? {};
  const title = runtime[id]?.modal?.title ?? '';
  const { t } = useTranslation();

  // Separate data fields from regular fields
  const { tabGroups: regularTabGroups, tabsWithRequired, dataFields } = useMemo(() => {
    if (!fields) return { tabGroups: new Map(), tabsWithRequired: new Set(), dataFields: [] };

    const tabGroups = new Map();
    const tabsWithRequired = new Set();
    const fieldsWithTab = [];
    const fieldsWithoutTab = [];
    const dataFields = [];

    Object.entries(fields).forEach(([key, field]) => {
      // Separate data fields
      if (field._key === 'data') {
        dataFields.push([key, field]);
        return;
      }

      if (field.tabTitle !== undefined) {
        fieldsWithTab.push([key, field]);
      } else {
        fieldsWithoutTab.push([key, field]);
      }
    });

    // Process fields with explicit tab titles
    fieldsWithTab.forEach(([key, field]) => {
      const { tabTitle } = field;
      if (!tabGroups.has(tabTitle)) {
        tabGroups.set(tabTitle, []);
      }
      tabGroups.get(tabTitle).push([key, field]);

      if (field.required) {
        tabsWithRequired.add(tabTitle);
      }
    });

    // Handle fields without tab assignment
    if (fieldsWithoutTab.length > 0) {
      let currentChunk = [];
      let chunkIndex = 1;

      fieldsWithoutTab.forEach(([key, field], index) => {
        currentChunk.push([key, field]);

        if (currentChunk.length === maxItemsPerTab ||
          index === fieldsWithoutTab.length - 1) {
          const autoTabTitle = chunkIndex === 1 ? t('Main') : t('Details');

          if (!tabGroups.has(autoTabTitle)) {
            tabGroups.set(autoTabTitle, []);
          }
          tabGroups.get(autoTabTitle).push(...currentChunk);

          if (currentChunk.some(([_, field]) => field.required)) {
            tabsWithRequired.add(autoTabTitle);
          }

          currentChunk = [];
          chunkIndex++;
        }
      });
    }

    return { tabGroups, tabsWithRequired, dataFields };
  }, [fields, maxItemsPerTab, t]);

  // State to manage active tab
  const [activeTab, setActiveTab] = useState('Main');

  useEffect(() => {
    setActiveTab(regularTabGroups.size > 0 ? (Array.from(regularTabGroups.keys())[0] ?? 'General') : 'Main');
  }, [regularTabGroups]);

  const handleClose = () => {
    dispatch(updateRuntimeData(`${id}.mode`, Mode.VIEW));
  };

  const edit = {};
  if (runtime[id]) {
    Object.keys(runtime[id])?.filter(item => fields?.some(field => field._key === item)).forEach((item) => { edit[item] = runtime[id][item].value; });
  }

  const fieldsWithDefaultValues = fields?.map((col: FieldInterface) => ({ ...col, ...(edit ? { value: edit[col._key] } : {}) }));

  const submitWidget: WidgetInterface = {
    _id: id,
    _content: fieldsWithDefaultValues,
    _type: 'submit',
    _subtype: mode ?? 'CREATE',
    label: 'Submit',
    _path: _.get(runtime, `${id}.path`),
  };

  return (
    <CModal fullscreen size="xl" onClose={handleClose} visible={mode === Mode.EXPAND_ROW || mode === Mode.CREATE || mode === Mode.EDIT}>
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>

      <CModalBody style={{ minHeight: '30vh' }}>
        <CNav variant="pills" style={{ gap: 5 }}>
          {Array.from(regularTabGroups.keys()).map((tabTitle) => (
            <CNavItem style={{ borderRadius: '8px', backgroundColor: 'blue' }} key={`tab-${tabTitle}`}>
              <CNavLink
                style={{ borderRadius: '8px' }}
                active={activeTab === tabTitle}
                onClick={() => setActiveTab(tabTitle)}
              >
                {t(tabTitle)}
                {tabsWithRequired.has(tabTitle) && (
                  <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                )}
              </CNavLink>
            </CNavItem>
          ))}
          {/* Add Files tab if there are data fields */}
          {dataFields.length > 0 && (
            <CNavItem style={{ borderRadius: '8px', backgroundColor: 'var(--accent)' }}>
              <CNavLink
                style={{ borderRadius: '8px' }}
                active={activeTab === 'Files'}
                onClick={() => setActiveTab('Files')}
              >
                {t('Gallery')}
              </CNavLink>
            </CNavItem>
          )}
        </CNav>

        <CTabContent>
          {/* Regular fields in tabs */}
          {Array.from(regularTabGroups.entries()).map(([tabTitle, tabFields]) => (
            <CTabPane
              key={`content-${tabTitle}`}
              visible={activeTab === tabTitle}
              className="p-3"
            >


              <div className="row g-3">
                {tabFields.map(([key, field]) => (
                  <div style={{ paddingBottom: '1rem' }} key={key} className={`col-md-${Math.min(tabFields.length) === 1 ? 12 : 4} ${fullWidthFields.includes(field._key) ? ' col-md-12' : ''}`}>
                    {
                      (mode === Mode.CREATE || mode === Mode.EDIT) && <Field widgetId={id} field={field} />
                    }

                    {
                      (mode === Mode.VIEW || mode === Mode.EXPAND_ROW) && (
                        <div style={{ minHeight: '6rem', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '8px', padding: '1rem' }} className="form-group">
                          <CFormLabel className="fw-bold">
                            {t(field._key)}
                          </CFormLabel>
                          <div className="form-control-plaintext" style={{ whiteSpace: 'pre-wrap' }}>
                            {selectedRow && renderFieldValue(selectedRow[field._key])}
                          </div>
                        </div>
                      )
                    }
                  </div>
                ))}
              </div>
            </CTabPane>
          ))}

          {/* Files tab content */}
          <CTabPane
            key="content-Files"
            visible={activeTab === 'Files'}
            className="p-3"
          >
            <SummaryContainer rawData={selectedRow?.data} />
            {/* <pre>
              {JSON.stringify(selectedRow?.data, null, 2)}
            </pre> */}

          </CTabPane>

        </CTabContent>

        {(mode === Mode.CREATE || mode === Mode.EDIT) && (
          <Widget widget={submitWidget} />
        )}
      </CModalBody>
    </CModal>
  );
};

export default ExpandedRowModal;
