import { Box } from '@mui/material';
import React from 'react';
import { PageInterface as ViewType, StatisticsSubtype, WidgetInterface as TypescriptWidget } from '../../types';
import DefaultLayout from '../../views/layout/DefaultLayout';
import NarrowHeaderLayout from '../../views/layout/NarrowHeaderLayout';
import { Widget } from '../WidgetRenderer';

export const ViewContext = React.createContext({
    isValid: true,
    setValidity: () => null,
});

export type WidgetProps = {
    widget: TypescriptWidget;
    subtype?: StatisticsSubtype | 'multi' | 'ocr-closeup';
};

export type Layout = 'defaultLayout' | 'narrowLayout' | 'blankLayout';
export const layoutMap: Record<Layout, React.FC> = {
    defaultLayout: DefaultLayout,
    narrowLayout: NarrowHeaderLayout,
    blankLayout: Box,
};

interface PageProps {
    page: ViewType;
    onValidChange?: (isValid: boolean) => void;
    layout?: Layout;
}

const evaluateCondition = (condition, context) => {};

const Page: React.FC<PageProps> = props => {
    const { page } = props;
    const styling = page._style; // Extracting style object from page.style

    const applyDynamicStyle = widgetId => {
        // Ensuring styling exists and accessing individualStyles safely
        const individualStyles = styling?.individualStyles?.[widgetId];
        if (!individualStyles) return {};

        const appliedStyles = Object.entries(individualStyles).reduce((acc, [key, value]) => {
            acc[key] = value.hasOwnProperty('$condition') ? evaluateCondition(value.$condition, {}) : value;
            return acc;
        }, {});

        // Log the styles applied to each widget
        console.log(`Styles applied to ${widgetId}: `, appliedStyles);
        return appliedStyles;
    };

    const PageWidgets = () => {
        const widgetsArray = page._widgets ?? [];

        if (!Array.isArray(widgetsArray)) {
            console.warn(`Page ${page._id ?? page._url} has no widgets array`);
            return null;
        }

        const renderWidget = widget => {
            const style = applyDynamicStyle(widget._id);
            return <Widget key={widget._id} widget={widget} style={style} />;
        };

        // Wrap grouped widgets and apply flex properties
        const groupedWidgets =
            styling?.flexGroups?.reduce((acc, group) => {
                const groupStyle = group.flexProperties; // Directly apply flexProperties to the wrapper
                const elements = group.elements
                    .map(elementId => {
                        const widget = widgetsArray.find(w => w._id === elementId);
                        return widget ? React.cloneElement(renderWidget(widget), { style: { ...widget.style, ...applyDynamicStyle(widget._id) } }) : null;
                    })
                    .filter(Boolean);
                if (elements.length > 0) {
                  acc.push(<div style={groupStyle} key={`group-${group.elements.join('-')}`}>
                                {elements}
                             </div>);
                }
                return acc;
            }, []) || [];

        // Filter out ungrouped widgets, apply individual styles, and render them
        const ungroupedWidgets = widgetsArray.filter(widget => !(styling?.flexGroups || []).some(group => group.elements.includes(widget.id))).map(widget => renderWidget(widget));

        return [...groupedWidgets, ...ungroupedWidgets];
    };

    // Layout logic remains unchanged
    let Layout = layoutMap[page.sideMenu ? 'defaultLayout' : 'blankLayout'];
    if (props.layout) Layout = layoutMap[props.layout];

    return (
        <Layout header={page.header} sidemenu={page.sideMenu} footer={page.footer}>
            <Box
                id="main-box"
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    overflow: 'hidden',
                    overflowY: 'auto',
                  gap: 5,
                  flexDirection: 'column',
                  display: 'flex',
                  '& #mapbox-mp-frame': { // Used for rendering user maps
                    width: '100%'
                  },
                    ...page._style?.individualStyles?.self,
                }}
            >
                <PageWidgets />
            </Box>
        </Layout>
    );
};

export default Page;
