import {
    CImage,
  CNavItem,
    CSidebar,
    CSidebarBrand,
    CSidebarHeader,
    CSidebarNav,
    CSidebarToggler,
} from '@coreui/react-pro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import { staticRoutes } from '../configuration/constants';
import { setSidebarHover, setSidebarUnfoldable } from '../services/redux/globalStateManager';
import useAppDispatch from '../services/redux/useAppDispatch';
import useAppSelect from '../services/redux/useAppSelect';
import { TextReveal } from './foldable/Text';
import webSocket from '../services/websocket/webSocket';
import { PageInterface } from '../types';
import { getLabel, getRoute } from '../utils';
import Icon from './mui-icon/Icon';
import { Stack } from '@mui/material';

// Remove when server supports these routes
export const addRoutes = [
  { name: 'Inventory', icon: 'Inventory' },
  { name: 'Leads', icon: 'ConnectWithoutContact' },
  { name: 'Customers', icon: 'SupportAgent' },
  { name: 'Tags', icon: 'Tag' },
  { name: 'General', icon: 'Tune' },
  { name: 'CARS-360', icon: 'DirectionsCar' },
  { name: 'Calendar', icon: 'CalendarToday' }
];

const AppSidebar = () => {
    const dispatch = useAppDispatch();
    const routes = useAppSelect('routes');
    const { sendMessage } = webSocket();
    const [pages, setPages] = useState<PageInterface[]>();
    const unfoldable = useAppSelect('sidebarUnfoldable');
    const sidebarShow = useAppSelect('sidebarShow');
    const { t } = useTranslation();
    const isRtl = useAppSelect('rtl');
    const navigate = useNavigate();
  const sidebarHover = useAppSelect('sidebarHover');


    useEffect(() => {
        if (routes?._metadata_pages) {
            sendMessage(routes._metadata_pages, 'GET')
            ?.then(response => {
                if (response) {

                  const { data } = response;

                  // Adding inventory, leads, gallery, customers, tags; until server supports the new routes

                  for (const route of addRoutes) {
                    if (data?.findIndex((item: any) => item._path?._id === '/' + route.name.toLowerCase()) === -1) {
                      const newRoute = createRouteObj(route.name, route.icon);
                      if (Array.isArray(response.data)) {
                        response.data.push(newRoute);
                      } else {
                        response.data = [newRoute];
                      }
                    }
                  }

                    setPages((response.data ?? (Array.isArray(response) ? response : [])).sort((a, b) => a.order - b.order));
                }
            })
            ?.catch(error => {
                console.error(error);
            });
        }
    }, [routes]);

    return (
        <CSidebar
            className="border-end"
                  position="fixed"
                  placement={isRtl ? 'end' : 'start'}
                  visible={sidebarShow}
                  unfoldable={unfoldable}
                  onMouseEnter={() => {
                      dispatch(setSidebarHover(true));
                  }}
                  onMouseLeave={() => {
                      dispatch(setSidebarHover(false));
                  }}
        >

        <div style={{ height: '54px' }}></div>
        <Stack style={{ position: 'absolute', top: '1.5rem' }} direction='row' gap={1} marginBottom={'-2rem'} margin={'0rem 0.5rem'}>

          <CImage
            src="/betec.png"
            alt="Betec Dashboard"
            className="d-inline-block"
            style={{ marginTop: -5 }}
            width={45}
          />
          <TextReveal>{t('Betec Dashboard')}</TextReveal>
        </Stack>
        <CSidebarHeader className="border-bottom">
          <CSidebarBrand className="d-none d-md-flex" onClick={() => navigate(staticRoutes.dashboard)}>

                </CSidebarBrand>
            </CSidebarHeader>
        <CSidebarNav style={{ padding: 0 }}>
                {Array.isArray(pages) && pages.map((item) => {
                    const url = getRoute(item._path);

                    if (!url) {
                        return null;
                    }
                    return (
                      <CNavItem key={item._id} href={url}
                        style={{
                          color: 'white',
                          alignItems: 'start',
                          ...(url === location.pathname && {
                            backgroundColor: 'var(--bt-primary-25)',
                            fontWeight: 700
                          })
                        }}



                      >
                            {item?.icon && <Icon sx={{ height: '30px', width: '30px', marginRight: '0.4rem', marginLeft: '0.4rem'}} name={item.icon} />}
                        <TextReveal>{t(getLabel(item).toLowerCase()) ?? ''}</TextReveal>
                        </CNavItem>
                    );
                })}
            </CSidebarNav>
            <CSidebarHeader className="border-top">
                <CSidebarToggler className="d-lg-flex"
                                 onClick={() => {
                                     dispatch(setSidebarUnfoldable(!unfoldable));
                                 }} />
            </CSidebarHeader>
        </CSidebar>

    );
};

export default React.memo(AppSidebar);

// Remove this function when server supports the new routes
const createRouteObj = (name: string, icon: string) => ({
  icon: icon,
  order: 4,
  widgets: [name.toLowerCase()],
  _id: name.toLowerCase(),
  _name: name,
  _path: {
    type: 'Exact',
    _id: '/' + name.toLowerCase()
  }
});
