import React, { useState } from 'react';
import {
  CameraAlt,
  ImageOutlined,
  LocalOffer,
  VideoLibrary,
  DirectionsCar,
  Settings,
  ExpandMore,
  ExpandLess,
  PictureAsPdf,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';

const GalleryContainer = styled(Box)({
  width: '100%',
  direction: 'rtl',
});

const GalleryItem = styled(Box)({
  borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const ItemButton = styled(Box)({
  minHeight: 48,
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
});

const NumberBadge = styled(Box)({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: '#1976d2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  fontSize: 14,
  marginLeft: 8,
});

const IconContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginRight: 12,
  color: 'rgba(255, 255, 255, 0.7)',
});

const ContentPanel = styled(Box)({
  padding: 16,
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  color: 'rgba(255, 255, 255, 0.7)',
});

const TextContainer = styled(Box)({
  textAlign: 'right',
  width: '100%',
});

const ImagesGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  // Mobile: 1 image per row
  gridTemplateColumns: '1fr',
  // Tablet: 3 images per row
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  // Desktop: 5 images per row
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
}));

const ImageThumbnail = styled('img')({
  width: '100%',
  aspectRatio: '16/9',
  objectFit: 'cover',
  borderRadius: 4,
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const ModalImage = styled('img')({
  width: '100%',
  aspectRatio: '16/9',
  objectFit: 'cover',
  display: 'block',
  borderRadius: 4,
});


const gallery360Data = [
  {
    id: 1,
    titleKey: 'Images 360',
    contentKey: 'Images 360 Content',
    number: '8',
    icon: <CameraAlt sx={{ fontSize: 20 }} />,
  },
  {
    id: 2,
    titleKey: 'Spots',
    contentKey: 'Spots Content',
    number: '',
    icon: <ImageOutlined sx={{ fontSize: 20 }} />,
  },
  {
    id: 3,
    titleKey: 'Protections',
    contentKey: 'Protections Content',
    icon: <LocalOffer sx={{ fontSize: 20 }} />,
  },
  {
    id: 4,
    titleKey: 'Damages And Details',
    contentKey: 'Damages And Details Content',
    icon: <LocalOffer sx={{ fontSize: 20 }} />,
  },
  {
    id: 5,
    titleKey: 'Add Video',
    contentKey: 'Add Video Content',
    icon: <VideoLibrary sx={{ fontSize: 20 }} />,
  },
  {
    id: 6,
    titleKey: 'Car Card',
    contentKey: 'Car Card Content',
    icon: <DirectionsCar sx={{ fontSize: 20 }} />,
  },
  {
    id: 7,
    titleKey: 'Tour 360',
    contentKey: 'Tour 360 Content',
    number: '',
    icon: <Settings sx={{ fontSize: 20 }} />,
  },
  {
    id: 8,
    titleKey: 'PDF Preview',
    fileUrl:
      'https://pdf-example-betec.s3.eu-central-1.amazonaws.com/%D7%A1%D7%99%D7%95%D7%A8360.pdf',
    icon: <PictureAsPdf sx={{ fontSize: 20 }} />,
  },
];

const Gallery360Item = ({ item, isOpen, toggleGallery, images360 }) => {
  const { t } = useTranslation();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imgUrl) => {
    setSelectedImage(imgUrl);
    setLightboxOpen(true);
  };

  const handleLightboxClose = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
  };

  return (
    <GalleryItem>
      <ItemButton onClick={() => toggleGallery(item.id)}>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <TextContainer>
            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: 14 }}>
              {t(item.titleKey)}
            </Typography>
          </TextContainer>
          {item.number && <NumberBadge>{item.number}</NumberBadge>}
        </Box>
        <IconContainer>
          {item.icon}
          {isOpen ? <ExpandLess sx={{ fontSize: 20 }} /> : <ExpandMore sx={{ fontSize: 20 }} />}
        </IconContainer>
      </ItemButton>
      <Collapse in={isOpen}>
        <ContentPanel>
          <TextContainer>
            {item.id === 1 && images360 ? (
              <ImagesGrid>
                {images360.map((imgUrl, index) => (
                  <ImageThumbnail
                    key={index}
                    src={imgUrl?.base64}
                    alt={`360 image ${index + 1}`}
                    onClick={() => handleImageClick(imgUrl)}
                  />
                ))}
              </ImagesGrid>
            ) : item.fileUrl ? (
              <>
                <iframe
                  src={item.fileUrl}
                  title="PDF Preview"
                  width="225px"
                  height="300px"
                  style={{ border: 'none' }}
                />
                <Box style={{ textAlign: 'center' }} sx={{ mt: 2 }}>
                  <a
                    href={item.fileUrl}
                    download
                    style={{ color: '#1976d2', textDecoration: 'none' }}
                  >
                    {t('Download PDF')}
                  </a>
                </Box>
              </>
            ) : (
              <Typography sx={{ fontSize: 14 }}>{t(item.contentKey)}</Typography>
            )}
          </TextContainer>
        </ContentPanel>
      </Collapse>

      {/* Modal for enlarged image */}
      <Dialog
        sx={{
          '& *': { border: 'none' },
          '& .MuiDialog-paper': {
            overflow: 'hidden',
            padding: 0,
            margin: 0,
            padding: '0.5rem',
            backgroundColor: 'var(--bt-bg)'
          }
        }}
        open={lightboxOpen}
        onClose={handleLightboxClose}
        maxWidth="lg"
        fullWidth
      >
        <Box
          style={{ backgroundColor: 'var(--bt-bg)' }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            padding: '1rem'
          }}
        >
          <DialogContentText
            style={{
              backgroundColor: 'var(--bt-bg)',
              textAlign: 'center',
              fontSize: '36px',
              color: 'var(--bt-primary-contrast)',
              margin: 0,
              padding: 0,
              position: 'absolute',
              width: '100%',
              left: 0,
              zIndex: 1,
              padding: '1rem'
            }}
          >
            {t(selectedImage?.label)}
          </DialogContentText>
          <Box sx={{ width: '100%' }}></Box>
          <IconButton
            onClick={handleLightboxClose}
            sx={{
              zIndex: 2,
              position: 'relative'
            }}
          >
            <CloseIcon sx={{ color: '#666' }} />
          </IconButton>
        </Box>
        <DialogContent
          style={{
            backgroundColor: 'var(--bt-bg)',
            padding: 0,
            margin: 0
          }}
        >
          {selectedImage && <ModalImage src={selectedImage?.base64} alt="Enlarged view" />}
        </DialogContent>
      </Dialog>
    </GalleryItem>
  );
};

const Gallery360 = (props) => {
  const [openItems, setOpenItems] = useState(new Set());
  // Assuming props.field.source.value is an array of image URLs for Images 360
  const images360ToDisplay = props.field.source.value;

  const toggleGallery = (itemId) => {
    setOpenItems((prev) => {
      const newOpenItems = new Set(prev);
      newOpenItems.has(itemId) ? newOpenItems.delete(itemId) : newOpenItems.add(itemId);
      return newOpenItems;
    });
  };

  return (
    <GalleryContainer>

      {gallery360Data.map((item) => (
        <Gallery360Item
          key={item.id}
          item={item}
          isOpen={openItems.has(item.id)}
          toggleGallery={toggleGallery}
          images360={images360ToDisplay}
        />
      ))}
    </GalleryContainer>
  );
};

export default Gallery360;
