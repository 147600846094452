import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  Camera,
  Image,
  Tag,
  PenTool as Tool,
  ChevronDown,
  ChevronRight,
  Check,
  VideoIcon,
  X as CloseIcon,
} from 'lucide-react';
import {
  Button,
  Collapse,
  Stack,
  Box,
  Typography,
  Badge,
  useMediaQuery,
  useTheme,
  Dialog,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import DownloadPdfButton from './DownloadPdfBtn';
import DownloadImagesButton from './DownloadImagesButton';

/**
 * FileImage interface representing an image with data and optional title
 */
export interface FileImage {
  data: string;      // Image data (base64 string or URL)
  title?: string;    // Optional title for the image
}

/**
 * Props interface for the Summary component
 */
export interface SummaryProps {
  // Required data arrays
  lprData: FileImage[];                // License plate images data
  tagsData: FileImage[];               // Tag images data
  damagesData: FileImage[];            // Damage images data
  angles360Data: (FileImage)[];  // 360 angle images data
  spotsData: (FileImage)[];      // Spot images data
  instaPhotosData: (FileImage)[]; // Instagram 360 images data

  // Optional configuration
  panoramicImgRef?: string;            // Optional fallback panoramic image reference
  onNavigateToSection?: (sectionId: string, path: string) => void; // Optional navigation callback
  onFinish?: () => void;               // Optional callback for finish button
  disableUrlCheck?: boolean;           // Optional flag to skip URL validation
  onVideoSelected?: (file: File, url: string) => void; // Optional video selection callback
}

/**
 * Gallery props interface
 */
interface GalleryProps {
  images: (FileImage | string)[];
  isMobile: boolean;
  onImageClick: (image: FileImage | string) => void;
}

/**
 * Summary Component - Displays a summary of the car inspection process
 * Responsive layout - accordion on mobile, horizontal split on desktop
 */
const Summary: React.FC<SummaryProps> = ({
  lprData = [],
  tagsData = [],
  damagesData = [],
  angles360Data = [],
  spotsData = [],
  instaPhotosData = [],
  panoramicImgRef,
  onNavigateToSection,
  onFinish,
  disableUrlCheck = false,
  onVideoSelected
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [lprs, setLprs] = useState<FileImage[]>([]);
  const [tags, setTags] = useState<FileImage[]>([]);
  const [damages, setDamages] = useState<FileImage[]>([]);
  const [openSection, setOpenSection] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState<string>('360-images');

  // State for fullscreen image dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<FileImage | string | null>(null);

  const navigate = useNavigate();
  const [stored360Images, setStored360Images] = useState<(FileImage)[]>([]);
  const [instaPhotos, setInstaPhotos] = useState<(FileImage)[]>([]);
  const [spots, setSpots] = useState<(FileImage)[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [videoURL, setVideoURL] = useState('');

  // Update state when props change
  useEffect(() => {
    setLprs(lprData);
    setTags(tagsData);
    setDamages(damagesData);
    setStored360Images(angles360Data);
    setSpots(spotsData);
    setInstaPhotos(instaPhotosData);

    // Cleanup function to release video URL object
    return () => {
      if (videoURL) {
        URL.revokeObjectURL(videoURL);
      }
    };
  }, [lprData.length, tagsData.length, damagesData.length, angles360Data.length, spotsData.length, instaPhotosData.length, disableUrlCheck, videoURL]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Validate file type
      if (file.type.startsWith('video/')) {
        // Create an object URL for the video file
        const objectURL = URL.createObjectURL(file);

        // Create a video element to load and check duration
        const video = document.createElement('video');
        video.src = objectURL;

        // Wait for video metadata to load
        video.onloadedmetadata = () => {
          const { duration } = video; // Get video duration in seconds
          if (duration > 15) {
            // Handle case where video exceeds 15 seconds
            toast.dismiss();
            toast.remove();
            toast.error(t('summary.videoTooLong'));
            // Cleanup object URL to prevent memory leak
            URL.revokeObjectURL(objectURL);
          } else {
            // Set the file and URL if the video is valid
            setSelectedFile(file);
            setVideoURL(objectURL);

            // Call the callback if provided
            if (onVideoSelected) {
              onVideoSelected(file, objectURL);
            }
          }
        };

        video.onerror = () => {
          toast.error(t('summary.fileReadError'));
          // Cleanup object URL to prevent memory leak
          URL.revokeObjectURL(objectURL);
        };
      } else {
        alert(t('summary.selectVideoFile'));
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleSectionClick = (sectionId: string) => {
    if (isMobile) {
      setOpenSection(openSection === sectionId ? null : sectionId);
    } else {
      setActiveSection(sectionId);
    }
  };

  const handleImageClick = (image: FileImage | string) => {
    if (!isMobile) {
      setSelectedImage(image);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedImage(null);
  };

  const menuItems = [
    {
      id: 'lpr',
      to: '/',
      title: t('License Plate'),
      icon: <Camera style={{ width: '1.25rem', height: '1.25rem' }} />,
      count: lprs.length,
      content: <Gallery images={lprs} isMobile={isMobile} onImageClick={handleImageClick} />,
    },
    {
      id: '360-images',
      to: '/angles',
      title: t('summary.sections.360Images'),
      icon: <Camera style={{ width: '1.25rem', height: '1.25rem' }} />,
      count: stored360Images.length,
      content: <Gallery images={stored360Images} isMobile={isMobile} onImageClick={handleImageClick} />,
    },
    {
      id: 'spots',
      to: '/spots',
      title: t('summary.sections.spots'),
      icon: <Image style={{ width: '1.25rem', height: '1.25rem' }} />,
      count: spots.length,
      content: <Gallery images={spots} isMobile={isMobile} onImageClick={handleImageClick} />,
    },
    {
      id: 'tags',
      to: '/tags',
      title: t('summary.sections.tags'),
      icon: <Tag style={{ width: '1.25rem', height: '1.25rem' }} />,
      count: tags.length,
      content: <Gallery images={tags} isMobile={isMobile} onImageClick={handleImageClick} />,
    },
    {
      id: 'damages',
      to: '/damages',
      title: t('summary.sections.damages'),
      icon: <Tool style={{ width: '1.25rem', height: '1.25rem' }} />,
      count: damages.length,
      content: <Gallery images={damages} isMobile={isMobile} onImageClick={handleImageClick} />,
    },
    {
      id: 'video',
      title: t('summary.sections.attachVideo'),
      icon: <VideoIcon style={{ width: '1.25rem', height: '1.25rem' }} />,
      count: selectedFile ? 1 : 0,
      content: <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        marginTop: isMobile ? '16px' : 0,
        padding: '24px',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '12px',
      }}>
        <input
          ref={fileInputRef}
          accept="video/*"
          type="file"
          style={{ display: 'none' }}
          id="video-upload-input"
          onChange={handleFileChange}
        />

        <label htmlFor="video-upload-input">
          <Button
            onClick={handleButtonClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'var(--accent)',
              color: '#fff',
              textTransform: 'none',
              borderRadius: '8px',
              padding: '10px 24px',
              fontWeight: 600,
              boxShadow: '0 4px 14px rgba(0, 0, 0, 0.2)',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: 'var(--accent)',
                filter: 'brightness(110%)',
                boxShadow: '0 6px 20px rgba(0, 0, 0, 0.25)',
                transform: 'translateY(-2px)'
              }
            }}
          >
            <VideoIcon style={{ marginRight: 8 }} />
            {t('summary.upload')}
          </Button>
        </label>

        {selectedFile && (
          <Typography sx={{
            fontSize: '0.875rem',
            color: 'var(--accent)',
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
            padding: '8px 16px',
            backgroundColor: 'rgba(var(--accent-rgb), 0.1)',
            borderRadius: '6px',
            fontWeight: 500
          }}>
            {selectedFile.name} <Check color="var(--accent)" />
          </Typography>
        )}

        {selectedFile && (
          <Box
            component="video"
            src={videoURL}
            controls
            sx={{
              maxWidth: '100%',
              width: '100%',
              maxHeight: '65vh',
              borderRadius: '12px',
              boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.2)',
              border: '1px solid rgba(255, 255, 255, 0.1)'
            }}
          />
        )}
      </Box>,
    }
  ];

  // Get active content for desktop view
  const activeContent = menuItems.find(item => item.id === activeSection)?.content;

  // Mobile Layout - Original Accordion
  if (isMobile) {
    return (
      <Stack
        sx={{
          overflow: 'hidden',
          minHeight: '100vh',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#0f172a',
          '& img:not(.main-img)': {
            background: '#1e293b',
            transition: 'transform 0.3s ease',
            borderRadius: '12px',
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Box sx={{
          position: 'relative',
          padding: '16px',
          backgroundColor: '#1e293b',
          borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
        }}>

          <Typography
            variant="h6"
            sx={{
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '1.25rem',
              color: '#f8fafc'
            }}
          >
            {t('summary.title')}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textAlign: 'center',
              color: '#94a3b8',
              fontSize: '0.875rem',
              mt: 1
            }}
          >
            {t('summary.total', { count: tagsData.length + damagesData.length + angles360Data.length + spotsData.length + instaPhotosData.length })}
          </Typography>

          <DownloadImagesButton
            lprData={lprs}
            tagsData={tags}
            damagesData={damages}
            angles360Data={stored360Images}
            spotsData={spots}
            instaPhotosData={instaPhotos}

          />
          <div
            id='download-pdf-btn'

          >
            <DownloadPdfButton
              lprData={lprs}
              tagsData={tags}
              damagesData={damages}
              angles360Data={stored360Images}
              spotsData={spots}
              instaPhotosData={instaPhotos}

            />
          </div>
        </Box>

        {/* Mobile Layout - Original Accordion */}
        <Box
          sx={{
            overflow: 'auto',
            p: { xs: 2, sm: 4 },
            '& > *:not(:first-of-type)': { mt: 3 },
            pb: '8rem',
            backgroundImage: 'linear-gradient(to bottom, #0f172a, #1e293b)',
            backgroundAttachment: 'fixed',
            height: '100%'
          }}
        >
          <Box sx={{
            maxWidth: '90%',
            margin: '0 auto',
            width: '100%'
          }}>
            {menuItems.map((item) => (
              <Box
                className="menu-item"
                key={item.id}
                sx={{
                  borderRadius: '16px',
                  overflow: 'hidden',
                  marginBottom: '16px',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                  border: '1px solid rgba(255, 255, 255, 0.05)',
                  backgroundColor: 'rgba(30, 41, 59, 0.5)',
                  backdropFilter: 'blur(10px)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2.5,
                    cursor: 'pointer',
                    transition: 'all 0.2s',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)'
                    }
                  }}
                  onClick={() => handleSectionClick(item.id)}
                >
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.75rem',
                    color: 'var(--accent)'
                  }}>
                    {item.icon}
                  </Box>

                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    flex: 1,
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                      fontWeight: 600,
                      fontSize: '1rem',
                      color: '#f1f5f9'
                    }}>
                      {item.title}
                    </Box>

                    {item.count > 0 && (
                      <Badge
                        sx={{
                          padding: '4px 12px',
                          borderRadius: '9999px',
                          fontWeight: 'bold',
                          backgroundColor: 'var(--accent)',
                          color: 'white',
                          fontSize: '0.75rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '24px'
                        }}
                      >
                        {item.count}
                      </Badge>
                    )}
                  </Box>

                  <ChevronDown
                    style={{
                      width: '1.25rem',
                      height: '1.25rem',
                      transition: 'transform 0.3s ease',
                      transform: openSection === item.id ? 'rotate(180deg)' : 'rotate(0deg)',
                      color: '#94a3b8'
                    }}
                  />
                </Box>

                <Collapse
                  in={openSection === item.id}
                  sx={{
                    borderTop: openSection === item.id ? '1px solid rgba(255, 255, 255, 0.05)' : 'none',
                    background: 'rgba(15, 23, 42, 0.7)'
                  }}
                >
                  {item.content && (
                    <Box sx={{
                      p: 3,
                      borderRadius: '0 0 16px 16px'
                    }}>
                      {item.content}
                    </Box>
                  )}
                </Collapse>
              </Box>
            ))}
          </Box>
        </Box>
      </Stack>
    );
  }

  // Desktop Layout - Horizontal Split
  return (
    <Stack
      sx={{
        overflow: 'hidden',
        '& *': { overflow: 'hidden' },
        minHeight: '100vh',

        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#0f172a',
        '& img:not(.main-img)': {
          background: '#1e293b',
          transition: 'transform 0.3s ease',
          borderRadius: '12px',
          width: '100%',
          height: '100%',
        },
      }}
    >
      <Box sx={{
        position: 'relative',
        padding: '16px',
        backgroundColor: '#1e293b',
        borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
      }}>

        <Stack direction='row'
          spacing={1}
          id='download-pdf-btn'
          sx={{
            position: 'absolute',
            right: '2rem',
            top: '2rem',
          }}
        >

          <DownloadImagesButton
            lprData={lprs}
            tagsData={tags}
            damagesData={damages}
            angles360Data={stored360Images}
            spotsData={spots}
            instaPhotosData={instaPhotos}

          />

          <DownloadPdfButton
            lprData={lprs}
            tagsData={tags}
            damagesData={damages}
            angles360Data={stored360Images}
            spotsData={spots}
            instaPhotosData={instaPhotos}

          />
        </Stack>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '1.25rem',
            color: '#f8fafc'
          }}
        >
          {t('summary.title')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            color: '#94a3b8',
            fontSize: '0.875rem',
            mt: 1
          }}
        >
          {t('summary.total', { count: tagsData.length + damagesData.length + angles360Data.length + spotsData.length + instaPhotosData.length })}
        </Typography>
      </Box>

      {/* Desktop Layout - Horizontal Split */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          height: 'calc(100vh - 80px)', // Account for header
          overflow: 'hidden'
        }}
      >
        {/* Sidebar Navigation */}
        <Box
          sx={{
            width: '320px',
            borderRight: '1px solid rgba(255, 255, 255, 0.05)',
            backgroundColor: 'rgba(30, 41, 59, 0.5)',
            backdropFilter: 'blur(10px)',
            padding: '24px',
          }}
        >
          {menuItems.map((item) => (
            <Box
              className={`menu-item ${activeSection === item.id ? 'active' : ''}`}
              key={item.id}
              sx={{
                borderRadius: '14px',
                marginBottom: '16px',
                backgroundColor: activeSection === item.id ? 'rgba(var(--accent-rgb), 0.1)' : 'transparent',
                border: activeSection === item.id ? '1px solid rgba(var(--accent-rgb), 0.2)' : '1px solid transparent',
                '&:hover': {
                  backgroundColor: activeSection === item.id ? 'rgba(var(--accent-rgb), 0.15)' : 'rgba(255, 255, 255, 0.05)',
                }
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2.5,
                  cursor: 'pointer',
                }}
                onClick={() => handleSectionClick(item.id)}
              >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: activeSection === item.id ? 'var(--accent)' : '#94a3b8',
                }}>
                  {item.icon}
                </Box>

                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  flex: 1,
                  marginLeft: '16px', // Increased space between icon and text
                }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    fontWeight: 600,
                    fontSize: '1rem',
                    color: activeSection === item.id ? 'var(--accent)' : '#f1f5f9',
                  }}>
                    {item.title}
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {item.count > 0 && (
                    <Badge
                      sx={{
                        padding: '0 8px',
                        borderRadius: '9999px',
                        fontWeight: 'bold',
                        backgroundColor: 'var(--accent)',
                        color: 'white',
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '20px',
                        minWidth: '20px',
                      }}
                    >
                      {item.count}
                    </Badge>
                  )}

                  <ChevronRight
                    style={{
                      width: '1rem',
                      height: '1rem',
                      color: activeSection === item.id ? 'var(--accent)' : '#94a3b8',
                      marginLeft: '8px'
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        {/* Content Area */}
        <Box
          sx={{
            flex: 1,
            padding: { sm: '30px', md: '40px' },
            backgroundImage: 'linear-gradient(to bottom, #0f172a, #1e293b)',
            backgroundAttachment: 'fixed',
          }}
        >
          {activeContent ? (
            <Box
              sx={{
                maxWidth: '90%',
                margin: '0 auto'
              }}
            >
              {activeContent}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                color: '#94a3b8',
                fontSize: '1rem',
              }}
            >
              {t('summary.selectSection')}
            </Box>
          )}
        </Box>
      </Box>

      {/* Fullscreen Image Dialog (Desktop only) */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="xl"
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(15, 23, 42, 0.95)',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.5)',
            width: '90vw',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
        }}>
          {selectedImage && typeof selectedImage === 'object' && 'title' in selectedImage && selectedImage.title && (
            <Box sx={{
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              padding: '16px',
              textAlign: 'center',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)'
            }}>
              <Typography sx={{
                color: 'white',
                fontSize: '1.5rem'
              }}>
                <strong>{selectedImage.title}</strong>
              </Typography>
            </Box>
          )}
          <IconButton
            onClick={handleCloseDialog}
            sx={{
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          overflow: 'hidden',
          padding: '24px'
        }}>
          <Box
            component="img"
            src={selectedImage && typeof selectedImage === 'object' && 'data' in selectedImage
              ? selectedImage.data
              : selectedImage as string}
            alt="Enlarged view"
            className='preview-img'
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'cover',
              width: '100%',
              borderRadius: '6px',
              boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.3)'
            }}
          />
        </Box>

      </Dialog>
    </Stack>
  );
};

/**
 * Gallery Component - Displays a grid of images
 * Responsive layout - 1 column on mobile, multi-column on desktop
 */
const Gallery: React.FC<GalleryProps> = ({ images, isMobile, onImageClick }) => {
  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: isMobile
        ? 'repeat(1, 1fr)' // 1 column on mobile
        : 'repeat(3, 1fr)', // Always 3 columns on desktop
      gap: isMobile ? '24px' : '30px',
      width: '100%'
    }}>
      {images.map((image, index) => (
        <Box
          key={index}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            overflow: 'hidden',
            transition: 'all 0.3s ease',
            border: '2px solid transparent',
            height: '100%',
            width: '100%',
            boxShadow: isMobile ? 'none' : '0 8px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: '12px',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: isMobile ? 'none' : '0 15px 30px rgba(0, 0, 0, 0.25)',
            }
          }}
          onClick={() => onImageClick(image)}
        >
          <Box
            sx={{
              paddingTop: '56.25%', // 16:9 aspect ratio
              position: 'relative',
              width: '100%'
            }}
          >
            <img
              src={typeof image === 'object' && 'data' in image ? image?.data : image}
              alt={`Image ${index + 1}`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
            />
          </Box>

          {(typeof image === 'object' && 'title' in image && image.title) && (
            <Box sx={{
              padding: '12px',
              width: '100%',
              backgroundColor: isMobile ? 'rgba(15, 23, 42, 0.9)' : 'transparent',
              borderTop: isMobile ? 'none' : '1px solid rgba(255, 255, 255, 0.1)'
            }}>
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '0.875rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  fontWeight: 500
                }}
              >
                {image.title}
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

/**
 * Simple function to print summary data in a readable format
 *
 * @param data The processed data arrays
 * @param options Optional display options
 */
function printSummaryData(data: {
  tagsData: any[];
  damagesData: any[];
  angles360Data: any[];
  spotsData: any[];
  instaPhotosData: any[];
  [key: string]: any[];
}, options = { truncateData: true }) {
  // Start logging group
  console.group('Summary Data');

  // Log overall counts
  console.log('Item counts:');
  Object.entries(data).forEach(([category, items]) => {
    if (Array.isArray(items)) {
      console.log(`  ${category}: ${items.length} items`);
    }
  });

  // Log each category
  Object.entries(data).forEach(([category, items]) => {
    if (!Array.isArray(items) || items.length === 0) return;

    console.group(`\n${category}:`);

    items.forEach((item, index) => {
      // Create a cleaned version of the item for logging
      const cleanedItem: any = { ...item };

      // Truncate data property if it's a string (likely base64)
      if (options.truncateData && typeof cleanedItem.data === 'string') {
        const dataStart = cleanedItem.data.substring(0, 30);
        const dataEnd = cleanedItem.data.length > 30
          ? ` ... (${cleanedItem.data.length} chars total)`
          : '';
        cleanedItem.data = dataStart + dataEnd;
      }

      console.log(`  [${index}]: `, cleanedItem);
    });

    console.groupEnd();
  });

  console.groupEnd();
}

export default Summary;
